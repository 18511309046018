import { useState, useCallback } from "react";

import { FormInstance, Menu, type MenuProps } from "antd";

import { useFlags } from "Services/FeatureFlagService";

import { AgentInternalTransfer } from "./AgentTransfer/AgentTransfer";
import { QueueInternalTransfer } from "./QueueTransfer/QueueTransfer";

import styles from "./InternalTransfer.module.scss";

const INTERNAL_TRANSFER_TYPE = {
    AGENT: "Agent",
    QUEUE: "Queue",
} as const;

type InternalTransferType = (typeof INTERNAL_TRANSFER_TYPE)[keyof typeof INTERNAL_TRANSFER_TYPE];

export type InternalTransferProps = {
    form: FormInstance;
};

export function InternalTransfer({ form }: InternalTransferProps): JSX.Element {
    const [transferType, setTransferType] = useState<InternalTransferType>(INTERNAL_TRANSFER_TYPE.AGENT);
    const { ckQueueInternalTransfers } = useFlags();

    const handleClick = useCallback<NonNullable<MenuProps["onClick"]>>(
        (event) => {
            setTransferType(event.key as InternalTransferType);
        },
        [setTransferType]
    );

    return (
        <>
            <Menu
                className={styles.horizontalMenu}
                onClick={handleClick}
                selectedKeys={[transferType]}
                mode="horizontal"
                inlineIndent={0}
            >
                <Menu.Item key={INTERNAL_TRANSFER_TYPE.AGENT}>Agent</Menu.Item>
                {ckQueueInternalTransfers && <Menu.Item key={INTERNAL_TRANSFER_TYPE.QUEUE}>Queue</Menu.Item>}
            </Menu>

            {transferType == INTERNAL_TRANSFER_TYPE.AGENT && <AgentInternalTransfer form={form} />}
            {ckQueueInternalTransfers && transferType == INTERNAL_TRANSFER_TYPE.QUEUE && (
                <QueueInternalTransfer form={form} />
            )}
        </>
    );
}
