import { gql } from "@apollo/client";

export const getLookerUser = gql`
    query user {
        lookerUser {
            id
            email
            folders {
                id
                name
            }
            group_ids
            canCreateDashboards
            permissions
        }
    }
`;

export const getDashboards = gql`
    query dashboards {
        dashboards {
            id
            title
            group
        }
    }
`;

export const createDashboard = gql`
    mutation ($dashboard: CreateDashboardInput!) {
        createDashboard(createDashboardInput: $dashboard) {
            id
            title
        }
    }
`;

export const updatePreferences = gql`
    mutation ($preferences: UpdatePreferencesInput!) {
        updatePreferences(updatePreferencesData: $preferences) {
            favoriteDashboard
        }
    }
`;
