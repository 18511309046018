import {
    ApolloClient,
    HttpLink,
    from,
    InMemoryCache,
    NormalizedCacheObject,
    ApolloQueryResult,
    OperationVariables,
    QueryOptions,
    MutationOptions,
    FetchResult,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorLink } from "@apollo/client/link/error";
import { NullOr } from "@regal-voice/shared-types";
import { pick } from "lodash";

import { typeDefs } from "Services/marketing-api/typedefs";
import { getApiUrl } from "Services/ProxyConfig";
import { fetch } from "Services/util/NetworkUtilService";

const customizeCachedFields = {
    typePolicies: {
        User: {
            fields: {
                reservations: {
                    merge(existing: any, incoming: any) {
                        // This is the current default behavior when no merge function is defined
                        // Defining a merge function protects the code from changes to default behavior and silences the warning
                        return incoming;
                    },
                },
            },
        },
    },
};

// set a context on your operation, which is used by other links further down the chain
// add api headers in context
const authLink = setContext(async (_, { headers }) => {
    return {
        headers,
    };
});

let apolloClient: NullOr<ApolloClient<NormalizedCacheObject>> = null;

export function createGraphQLClient(): ApolloClient<NormalizedCacheObject> {
    apolloClient = new ApolloClient({
        link: from([
            authLink,
            new ErrorLink(({ graphQLErrors, networkError, response }) => {
                const { requestId } = (response as any) || {};
                if (requestId) {
                    if (graphQLErrors) {
                        (graphQLErrors as any).requestId = requestId;
                    } else if (networkError) {
                        (networkError as any).requestId = requestId;
                    }
                }
            }),
            new HttpLink({
                uri: `${getApiUrl()}/graphql`,
                credentials: "same-origin",
                fetch: (url, options) =>
                    fetch(url, options).then(
                        async (res) =>
                            new Response(
                                JSON.stringify({
                                    ...(await res.json()),
                                    requestId: res.headers.get("X-RV-Request-ID"),
                                }),
                                pick(res, "ok", "status", "statusText", "headers")
                            )
                    ),
            }),
        ]),
        cache: new InMemoryCache(customizeCachedFields),
        typeDefs,
    });
    return apolloClient;
}

export function getApolloClient(): ApolloClient<NormalizedCacheObject> {
    if (apolloClient) {
        return apolloClient;
    }
    return createGraphQLClient();
}

export async function apolloQuery<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>
): Promise<ApolloQueryResult<T>> {
    const client = getApolloClient();
    return client.query(options);
}

export function apolloMutation<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>
): Promise<FetchResult<T>> {
    const client = getApolloClient();
    return client.mutate(options);
}
