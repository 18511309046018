import { forceGetConfig } from "Components/elements/UserNotifier/helpers/config.helpers";
import { selectBrandMessagingConfig } from "Services/state/brand";
import { reduxStore } from "Services/state/Storage";

export function getEventConfigs() {
    return forceGetConfig(selectBrandMessagingConfig(reduxStore.getState()) || {});
}

export function getTaskAudioConfig(taskTitle: string): { url?: string; loop?: boolean } {
    if (!getEventConfigs()?.enabled) {
        return {};
    }

    const config = getEventConfigs()?.events?.["reservation.created"]?.[taskTitle];

    return config || {};
}
