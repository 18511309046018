import { MessageType } from "@regal-voice/shared-types";

import { FlagTypes } from "Application/thirdParty/launchDarkly";
import {
    addEventEmitterListener,
    removeAllEventEmitterListeners,
    removeEventEmitterListener,
} from "Services/server-sent-events/EventEmitter";

import { reduxStore } from "../Storage";
import {
    handleReservationAccepted,
    handleReservationCreated,
    backfillExistingActiveTasks,
    handleReservationCanceled,
    handleReservationCompleted,
    handleReservationRejected,
    handleReservationRescinded,
    handleReservationTimedOut,
    handleReservationWrapup,
    handleTaskUpdate,
} from "./Thunks";
import { mapReservationCreatedSSEToRVTask } from "./Utils";

export type ReservationCreatedSSETaskData = Required<MessageType["twilio"]["reservation.created"]["task"]> & {
    reservationCreatedAt: string;
    reservationUpdatedDate: string;
};

export function dispatchHandleReservationCreated(
    data: MessageType["twilio"]["reservation.created"],
    { multipleCalls }: Partial<FlagTypes>
): void {
    const rvTask = mapReservationCreatedSSEToRVTask(data.task as ReservationCreatedSSETaskData);
    reduxStore.dispatch(handleReservationCreated({ task: rvTask, flags: { multipleCalls } }));
}

function dispatchHandleReservationAccepted({
    reservation: { sid: reservationSid },
    task: { reservationAcceptedAt } = {},
}: MessageType["twilio"]["reservation.accepted"]) {
    reduxStore.dispatch(handleReservationAccepted(reservationSid, reservationAcceptedAt));
}

function dispatchHandleReservationCanceled({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.canceled"]) {
    reduxStore.dispatch(handleReservationCanceled(reservationSid));
}

function dispatchHandleReservationCompleted({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.completed"]) {
    reduxStore.dispatch(handleReservationCompleted(reservationSid));
}

function dispatchHandleReservationRejected({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.rejected"]) {
    reduxStore.dispatch(handleReservationRejected(reservationSid));
}

function dispatchHandleReservationRescinded({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.rescinded"]) {
    reduxStore.dispatch(handleReservationRescinded(reservationSid));
}

function dispatchHandleReservationTimedOut({
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.timeout"]) {
    reduxStore.dispatch(handleReservationTimedOut(reservationSid));
}

function dispatchHandleReservationWrapup({
    task: { reservationUpdatedDate },
    reservation: { sid: reservationSid },
}: MessageType["twilio"]["reservation.wrapup"]) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    reduxStore.dispatch(handleReservationWrapup({ reservationSid, reservationUpdatedDate: reservationUpdatedDate! }));
}

function dispatchHandleTaskUpdate({ task }: MessageType["twilio"]["task.updated"]) {
    reduxStore.dispatch(handleTaskUpdate(task));
}

export function unSubscribeToTaskSSE(): void {
    removeAllEventEmitterListeners("reservation.created");

    removeEventEmitterListener("reservation.accepted", dispatchHandleReservationAccepted);

    removeEventEmitterListener("reservation.canceled", dispatchHandleReservationCanceled);

    removeEventEmitterListener("reservation.completed", dispatchHandleReservationCompleted);

    removeEventEmitterListener("reservation.rejected", dispatchHandleReservationRejected);

    removeEventEmitterListener("reservation.rescinded", dispatchHandleReservationRescinded);

    removeEventEmitterListener("reservation.timeout", dispatchHandleReservationTimedOut);

    removeEventEmitterListener("reservation.wrapup", dispatchHandleReservationWrapup);

    removeEventEmitterListener("task.updated", dispatchHandleTaskUpdate);
}

export function subscribeToTaskSSE({ multipleCalls }: { multipleCalls?: boolean }): void {
    const dispatch = reduxStore.dispatch;

    dispatch(backfillExistingActiveTasks({}));

    addEventEmitterListener("twilio", "reservation.created", (data: MessageType["twilio"]["reservation.created"]) =>
        dispatchHandleReservationCreated(data, { multipleCalls })
    );

    addEventEmitterListener("twilio", "reservation.accepted", dispatchHandleReservationAccepted);

    addEventEmitterListener("twilio", "reservation.canceled", dispatchHandleReservationCanceled);

    addEventEmitterListener("twilio", "reservation.completed", dispatchHandleReservationCompleted);

    addEventEmitterListener("twilio", "reservation.rejected", dispatchHandleReservationRejected);

    addEventEmitterListener("twilio", "reservation.rescinded", dispatchHandleReservationRescinded);

    addEventEmitterListener("twilio", "reservation.timeout", dispatchHandleReservationTimedOut);

    addEventEmitterListener("twilio", "reservation.wrapup", dispatchHandleReservationWrapup);

    addEventEmitterListener("twilio", "task.updated", dispatchHandleTaskUpdate);
}
