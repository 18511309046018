import { useEffect, useState, useMemo } from "react";

import { Popover } from "antd";
import { debounce } from "lodash";

export type PopoverWithTrackingProps = React.ComponentProps<typeof Popover> & {
    id: string;
};

export function PopoverWithTracking({ id, ...rest }: PopoverWithTrackingProps): JSX.Element {
    const [key, setKey] = useState(id);
    const resizeListener = useMemo(
        () =>
            debounce(
                () => {
                    const width = window.innerWidth;
                    const height = window.innerHeight;
                    setKey(`${id}-${width}-${height}`);
                },
                500,
                { leading: true }
            ),
        [id]
    );

    useEffect(() => {
        setKey(id);
    }, [id]);

    useEffect(() => {
        window.addEventListener("resize", resizeListener);
        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, [resizeListener]);

    return <Popover {...rest} key={key} />;
}
