import { UserContext } from "App/contexts/AuthenticatedUserContext";
import { CallBar } from "Pages/agentDesktop/agentInterface/CallBar";

import { useAppSetupHooks } from "../Shared/Hooks/useAppSetupHooks";
import { useRegalAuthContext } from "./RegalAuthContext";

export default function UserProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
    const { brand } = useAppSetupHooks();
    const auth = useRegalAuthContext();
    const providerValue = { user: auth.user, brand };

    return (
        <UserContext.Provider value={providerValue}>
            {children}
            <CallBar />
        </UserContext.Provider>
    );
}
