import { TaskAttributes as RVTaskAttributes, RVTask } from "@regal-voice/shared-types";

import { ManagerAction } from "Pages/tasks/TaskDetails/ManagerCallControls/ManagerCallControlConstants";
import { configureServer } from "Services/AbstractApiService";
import { getLogger } from "Services/LoggingService";
import { getConvUrl } from "Services/ProxyConfig";

import { Attachment } from "./state/agent/desktopUI/AgentDesktopUISlice";
import { ConnectionQualityStateEntry } from "./state/conferences/ConnectionQualityStateSlice";
import { TaskAttributes } from "./Task.service";

export type OutboundCallBody = {
    contactPhone: string;
    regalVoicePhone: string;
    triggeredFromDialpad: boolean;
} & Partial<TaskAttributes>;

export type UpdateTaskBody = {
    attributes: Partial<RVTaskAttributes>;
};

const logger = getLogger("ConversationsApiService");

const makeRequest = configureServer({
    host: getConvUrl(),
    performCaseConversion: false,
});

export async function authenticateConversationsUser(): Promise<{ token: string }> {
    return makeRequest<Promise<{ token: string }>>({
        endpoint: `/authentication/twilio-token`,
        errors: {
            notFound: `Could not load conversations token.`,
            other: `Could not load conversations token.`,
        },
    });
}

export async function sendOutboundSms({
    contactPhone,
    regalVoicePhone,
    profileId,
}: {
    contactPhone: string;
    regalVoicePhone: string;
    profileId?: string;
}): Promise<any> {
    return makeRequest<Promise<{ token: string }>>({
        endpoint: `/conversations/outbound-sms`,
        errors: {
            notFound: `Could not load conversations token.`,
            other: `Could not load conversations token.`,
        },
        body: {
            contactPhone,
            regalVoicePhone,
            profileId,
        },
    });
}

export async function joinConference(body: {
    agent: {
        uri: string;
        email: string;
        attributes: Record<string, any>;
    };
    task: RVTask;
    brand: {
        accountSid: string;
    };
}): Promise<{ success: boolean; canContact?: boolean }> {
    return makeRequest({
        endpoint: `/conferences/join-call`,
        errors: {
            notFound: "404",
            other: "Call could not be connected",
        },
        body,
        retryAttempts: 1,
        skipDefaultErrorHandling: true,
    });
}

export async function voicemailDrop({
    customerCallSid,
    campaignId,
}: {
    customerCallSid: string;
    campaignId?: string;
}): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/call-controls/voicemail-drop`,
        errors: {
            notFound: "Could not be found",
            other: "Error sending voicemail",
        },
        body: {
            customerCallSid,
            campaignId,
        },
    });
}

export async function dropParticipant(body: { conferenceSid: string; callSid: string; taskSid: string }): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/call-controls/drop-participant`,
        errors: {
            notFound: "Conference not found",
            other: "Could not hold the conference participant",
        },
        body,
    });
}

export async function holdParticipant(body: { callSid: string; conferenceSid: string; hold: boolean }): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/call-controls/hold`,
        errors: {
            notFound: "Conference not found",
            other: "Could not hold the conference participant",
        },
        body,
    });
}

export async function getCallProperties(body: { callSid: string }): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/conferences/call-properties`,
        errors: {
            notFound: "Call properties not found",
            other: "Call properties could not be fetched",
        },
        body,
    });
}

export async function getConferenceParticipants(body: { conferenceFriendlyId: string }): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/conferences/conference-participants`,
        errors: {
            notFound: "Call properties not found",
            other: "Call properties could not be fetched",
        },
        body,
    });
}

export async function manageRecording(body: {
    callSid: string;
    status: "paused" | "in-progress";
    conferenceSid?: string | undefined;
    conferenceFriendlyId: string;
}): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/call-controls/recordings`,
        errors: {
            notFound: "Conference not found",
            other: "Could not change recording status of the conference",
        },
        body,
    });
}

export async function emitMuteEvent(body: { mute: boolean; conferenceFriendlyId: string }): Promise<any> {
    return makeRequest({
        endpoint: `/call-controls/notify-mute`,
        errors: {
            notFound: "Conference not found",
            other: "Could not notify of mute changing",
        },
        body,
    });
}

export async function wrapTask(body: { taskSid: string }): Promise<any> {
    if (!body.taskSid) {
        logger.log("No task sid provided");
        return;
    }

    const result = await makeRequest({
        endpoint: `/tasks/wrap-task`,
        errors: {
            notFound: "Task could not be found.",
            other: "Could not wrap the task.",
        },
        body,
    });

    return result;
}

export async function acceptTask(body: RVTask): Promise<any> {
    if (!body) {
        logger.log("No task provided", body);
        return;
    }

    const result = await makeRequest({
        endpoint: `/tasks/accept`,
        errors: {
            notFound: "Task could not be found.",
            other: "Could not accept the task.",
        },
        body,
        retryAttempts: 1,
        skipDefaultErrorHandling: true,
    });

    return result;
}

export function startManagerAction(body: {
    taskSid: string;
    managerContactUri: string;
    twilioWorkerSid: string;
    action: ManagerAction;
    status: string;
    callSid: string | null;
}): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/conferences/manager-action`,
        errors: {
            notFound: "Task could not be found",
            other: "Could not start manager action",
        },
        body,
    });
}

export function stopManagerAction(taskSid: string, callSid: string): Promise<any> {
    return makeRequest<Promise<any>>({
        method: "DELETE",
        endpoint: `/conferences/manager-action/${taskSid}/${callSid}`,
        errors: {
            notFound: "Task could not be found",
            other: "Could not stop manager action",
        },
    });
}

export async function addParticipantToConference(body: {
    conferenceFriendlyId: string;
    regalVoicePhone: string;
    externalPhoneNumber: string;
    taskSid: string;
}): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/conferences/add-participant`,
        errors: {
            notFound: "Could not add participant to call",
            other: "Could not add participant to call",
        },
        body,
    });
}

export async function agentInternalTransfer(body: {
    taskSid: string;
    sourceWorkerUri: string;
    targetWorkerUri?: string;
    queueSid?: string;
    queueName?: string;
}): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/transfers/agent`,
        errors: {
            notFound: "Could not find the specified agent.",
            other: "Could not tranfer the task to agent.",
        },
        body,
    });
}

export async function outboundFromTask(body: {
    agent: {
        uri: string;
        email: string;
        attributes: Record<string, any>;
    };
    task: RVTask;
    brand: {
        accountSid: string;
    };
}): Promise<{ success: boolean; canContact?: boolean }> {
    return makeRequest({
        endpoint: `/conferences/create-call`,
        errors: {
            notFound: "Conference not found",
            other: "Could not create the outbound call.",
        },
        body,
        retryAttempts: 1,
        skipDefaultErrorHandling: true,
    });
}

export async function outboundCall(body: OutboundCallBody): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/tasks/manual-outbound-call-task`,
        errors: {
            notFound: "Conference not found",
            other: "Could not create the manual outbound call",
        },
        body,
    });
}

export async function taskSummary({
    phone,
    email,
    agentID,
    agentEmail,
    objections,
    disposition,
    dispositionedBy,
    notes,
    sid,
    taskAttributes,
    audioStatistics,
}: {
    sid: string;
    phone?: string;
    email?: string;
    agentID: string;
    agentEmail: string;
    objections?: Array<string>;
    disposition: string;
    dispositionedBy: "agent" | "system";
    notes?: string;
    taskAttributes: Omit<TaskAttributes, "name" | "to" | "originatingTaskSid">;
    audioStatistics: ConnectionQualityStateEntry;
}): Promise<{ success: boolean }> {
    return makeRequest<Promise<any>>({
        endpoint: `/tasks/summarize-task`,
        errors: {
            notFound: "Could not be found",
            other: "Error summarizing task",
        },
        body: {
            phone,
            email,
            agentID,
            agentEmail,
            objections,
            disposition,
            dispositionedBy,
            notes,
            sid,
            taskAttributes,
            audioStatistics,
        },
        retryAttempts: 1,
    });
}

export async function updateTaskInTwilio(
    taskSid: string,
    body: { attributes: Partial<RVTaskAttributes> }
): Promise<any> {
    return makeRequest<Promise<any>>({
        endpoint: `/tasks/${taskSid}/update`,
        errors: {
            notFound: "Task not found",
            other: "Task couldn't be updated",
        },
        body,
    });
}

export async function authenticateGmail(): Promise<{ url: string }> {
    return makeRequest<Promise<{ url: string }>>({
        endpoint: `/gmail-integration/oauth-redirect-url`,
        errors: {
            notFound: "User not found.",
            other: "Could not authenticate.",
        },
    });
}

export async function sendEmail(newEmailDto: {
    attachments?: Attachment[];
    cc?: string[];
    bcc?: string[];
    to: string[];
    inReplyToRvMessageId?: string;
    subject: string;
    senderEmail: string;
    body: string;
}) {
    const formData = new FormData();
    formData.append("subject", newEmailDto.subject);
    formData.append("senderEmail", newEmailDto.senderEmail);
    formData.append("body", newEmailDto.body);

    newEmailDto.to.forEach((cc) => {
        formData.append("to[]", cc);
    });
    if (newEmailDto.bcc) {
        newEmailDto.bcc.forEach((cc) => {
            formData.append("bcc[]", cc);
        });
    }
    if (newEmailDto.cc) {
        newEmailDto.cc.forEach((cc) => {
            formData.append("cc[]", cc);
        });
    }

    if (newEmailDto.inReplyToRvMessageId) {
        formData.append("inReplyToRvMessageId", newEmailDto.inReplyToRvMessageId);
    }
    if (newEmailDto.attachments) {
        Object.values(newEmailDto.attachments).forEach((file) => {
            // (for now)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formData.append("files", new Blob([file.media], { type: file.type }), file.name);
        });
    }
    return makeRequest<
        Promise<{ sentEmail: string; emailMessageIdentifiers?: { rvThreadId: string; rvEmailMessageId: string } }>
    >({
        endpoint: `/email/new-message`,
        body: formData,
        method: "POST",
        skipDefaultErrorHandling: true,
        errors: {
            notFound: "Email could not be sent",
            other: "Email could not be sent",
        },
    });
}

export async function createManualOutboundEmailTask({
    contactPhone,
    contactEmail,
    agentEmail,
    profileId,
}: {
    contactPhone?: string | null;
    contactEmail?: string;
    agentEmail: string;
    profileId?: string;
}): Promise<any> {
    return makeRequest<Promise<{ token: string }>>({
        endpoint: `/email/outbound-email`,
        errors: {
            notFound: `Could not create email task.`,
            other: `Could not create email task.`,
        },
        body: {
            contactPhone,
            contactEmail,
            agentEmail,
            profileId,
        },
    });
}
