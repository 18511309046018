// import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectBrandMessagingConfig } from "Services/state/brand";

import { forceGetConfig } from "../helpers/config.helpers";

import type { MessagingConfig } from "Types/Brand";

export function useEventConfigs(): { eventConfigs: () => MessagingConfig } {
    const messagingConfig = useSelector(selectBrandMessagingConfig);
    // const eventConfigs = useMemo(() => {
    //     const configs = forceGetConfig(messagingConfig);
    //     if (configs.enabled) {
    //         return configs;
    //     }
    //     return undefined;
    // }, [JSON.stringify(forceGetConfig(messagingConfig))]);

    return { eventConfigs: () => forceGetConfig(messagingConfig) };
}
