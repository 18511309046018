import { MessageType } from "@regal-voice/shared-types";
import { EventEmitter } from "events";

const eventEmitter = new EventEmitter();

export function removeEventEmitterListener(eventName: string, callback: (...args: any[]) => void): void {
    eventEmitter.removeListener(String(eventName), callback);
}

export function removeAllEventEmitterListeners(eventName: string): void {
    eventEmitter.removeAllListeners(String(eventName));
}

export function addEventEmitterListener<T extends keyof MessageType, K extends keyof MessageType[T]>(
    namespace: T,
    event: K,
    callback: (data: MessageType[T][K], event: K) => void
): void {
    const eventName = String(event);
    removeEventEmitterListener(eventName, callback);
    eventEmitter.on(eventName, callback);
}

export default eventEmitter;
