import dayjs, { extend, Ls, Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import durationPlugin, { DurationUnitType } from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocalePlugin from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";

// abstracting this into a function enables us to invoke it when testing in isolation
export function setupDayjs() {
    extend(advancedFormat);
    extend(durationPlugin);
    extend(localizedFormat);
    extend(relativeTime);
    extend(timezone);
    extend(updateLocalePlugin);
    extend(utc);
    dayjs.updateLocale("en", {
        relativeTime: {
            ...Ls.en.relativeTime,
            s: (number: number) => (number < 15 ? "a few seconds" : `about ${Math.round(number / 5) * 5} seconds`),
        },
    });
}

setupDayjs();

export enum DateFormat {
    DEFAULT = "MM/DD/YYYY",
    DATE_TIME_DEFAULT = "MM/DD/YYYY h:mm A z",
    DATE_TIME_TZ_INDEPENDENT = "MM/DD/YYYY h:mm A",
    ISO_8601 = "YYYY-MM-DDTHH:mm:ss.SSSZ",
    INGEST_FORMAT = "YYYY-MM-DD HH:mm:ssZ",
    PRETTY_FULL = "MM-DD-YY hh:mm A z",
    PRETTY_AT = "M/D/YY [at] LT z",
    PRETTY_SHORT_TIME_WDAY = "ddd, h:mm A z",
    PRETTY_SHORT_TIME_WDAY_NO_TIMEZONE = "M/D/YY [at] h:mm A",
    PRETTY_SHORT_TIME_DEFAULT = "h:mm a",
}

/***
 *
 * US Timezones.
 *
 */
export enum Zone {
    et = "America/New_York",
    ct = "America/Chicago",
    mt = "America/Denver",
    pt = "America/Los_Angeles",
    akt = "America/Anchorage",
    hat = "Pacific/Honolulu",
}

export enum ZoneTZIndependant {
    et = "US/Eastern",
    ct = "US/Central",
    mt = "US/Mountain",
    pt = "US/Pacific",
    akt = "US/Alaska",
    hat = "US/Hawaii",
}

export const timezoneMapping: { [key: string]: string } = {
    [Zone.et]: "Eastern",
    [Zone.ct]: "Central",
    [Zone.mt]: "Mountain",
    [Zone.pt]: "Pacific",
    [Zone.akt]: "Alaska",
    [Zone.hat]: "Hawaii-Aleutian",
};

export const shortTimezoneMapping: { [key: string]: string } = {
    [Zone.et]: "ET",
    [Zone.ct]: "CT",
    [Zone.mt]: "MT",
    [Zone.pt]: "PT",
    [Zone.akt]: "AKT",
    [Zone.hat]: "HAT",
};

export const acceptedRegalDateFormatLink =
    "https://regalvoice.slab.com/posts/property-data-types-name-limits-24yhvlg7#hsn7u-dates";
export const acceptedRegalDateFormats = [
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} [+-]\d{2}:\d{2}$/, // yyyy-MM-dd HH:mm:ss ZZ
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} Z$/, // yyyy-MM-dd HH:mm:ss Z
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, // yyyy-MM-dd HH:mm:ss
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/, // yyyy-MM-ddTHH:mm:ss.SSSZZ
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, // yyyy-MM-ddTHH:mm:ss.SSSZ
    /^\d{4}-\d{2}-\d{2}$/, // yyyy-MM-dd
];

/**
 * @param timestamp number number of milliseconds since the epoch
 * @return string a pretty formatted string showing the difference from `timestamp` to now
 */
export function prettyTimeSince(timestamp: number | Date | string): string {
    const durationDiff = dayjs().diff(dayjs(timestamp));
    let format;
    if (durationDiff >= 3600 * 24000) {
        // over a day
        format = `D:HH:mm:ss`;
    } else if (durationDiff >= 3600 * 1000) {
        // over an hour
        format = `HH:mm:ss`;
    } else {
        // less than an hour
        format = `mm:ss`;
    }
    return dayjs.duration(durationDiff).format(format);
}

/**
 *
 * @param {Dayjs} date
 * @param {Zone} timeZone
 */
export function isDaylightSavings(date: string, timeZone: Zone): boolean {
    return dayjs.tz(date, timeZone).utcOffset() - dayjs.tz("2000-07-01", timeZone).utcOffset() == 0;
}

/**
 *
 *
 */
export function getTimezoneOptions(): Record<Zone, string> {
    return Object.values(Zone).reduce((acc: Record<Zone, string>, zone: Zone) => {
        // @todo we could include DST here.
        acc[zone] = [timezoneMapping[zone], "Time", "Zone"].join(" ");
        return acc;
    }, {} as Record<Zone, string>);
}

/**
 *
 * @param date string|number|Date|Dayjs assumed to be in UTC or have zone information
 * @param { timezone } Zone
 * @param { format } DateFormat | string
 */
export function format(
    date: string | number | Date | Dayjs,
    { timezone, format = DateFormat.ISO_8601 }: { timezone?: Zone; format?: DateFormat } = {}
): string {
    const time = timezone ? dayjs(date).tz(timezone) : dayjs(date);
    return time.format(format);
}

/**
 * @param date string|number|Date|Dayjs assumed to be in UTC
 * @param { timezone } Zone
 * @param { format } DateFormat | string
 */
export function buildInZone(date: string, timezone: Zone): Dayjs {
    return dayjs.tz(date, timezone);
}

/**
 * @param date to display distance to
 */
export function relativeDisplay(date: string | number | Date | Dayjs): string {
    return dayjs(date).fromNow();
}

/**
 * @params seconds to display relative size
 */
export function humanizedDuration(amount: number, unit?: "seconds" | "minutes" | "hours" | "days"): string {
    return dayjs.duration(amount, unit).humanize();
}

export function prettyTime(timeInSeconds: number) {
    return dayjs().startOf("day").second(timeInSeconds).format("H:mm:ss");
}

export function getPeriodFromSeconds(lastStatusUpdateDate: string | null) {
    if (!lastStatusUpdateDate) {
        return;
    }
    const lastStatusUpdateDateUnix = dayjs(lastStatusUpdateDate).unix();
    const secondsDifference = dayjs().unix() - lastStatusUpdateDateUnix;
    const month = Math.floor(secondsDifference / (60 * 60 * 24 * 31));
    const days = Math.floor((secondsDifference / (60 * 60 * 24)) % 31);
    const hours = Math.floor((secondsDifference % (3600 * 24)) / 3600);
    const minutes = Math.floor((secondsDifference % 3600) / 60);
    const seconds = Math.floor(secondsDifference % 60);
    const prepandZeroToTime = (value: number) => {
        return value < 10 ? `0${value}` : value;
    };
    if (month) {
        return ``;
    } else if (days) {
        return `${days}d ${hours ? `${hours}h` : ""}`;
    } else if (hours) {
        return `${hours}h ${minutes ? `${minutes}min` : ""}`;
    } else {
        return `${minutes}:${prepandZeroToTime(seconds)}`;
    }
}

function range(start: number, end: number) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export function disabledTime(date: Dayjs | null) {
    if (date) {
        return {
            disabledHours: () => range(0, 0),
            disabledMinutes: () => range(0, 0),
            disabledSeconds: () => [0, 0],
        };
    }
    return {
        disabledHours: () => range(0, 24),
        disabledMinutes: () => range(0, 59),
        disabledSeconds: () => [0, 59],
    };
}

export function convertMillisecondsToSeconds(timestamp: string): number {
    return parseInt(timestamp) / 1000;
}

export function convertSecondsToMilliseconds(timestamp: string): number {
    return parseInt(timestamp) * 1000;
}

/**
 * @params duration, precision: seconds
 */
export function exactDuration(
    durationValue: number,
    unit: DurationUnitType,
    options?: { shortFormat: boolean }
): string {
    const durationObject = dayjs.duration(durationValue, unit);
    let formatted;
    let timeUnits;
    if (options?.shortFormat) {
        formatted = durationObject.format("Y[y] M[m] D[d] H[hr] m[min] s[sec]");
        timeUnits = formatted.match(/\b([1-9]\d*)\s*((?:y|m|d|hr|min|sec)s?)\b/g);
    } else {
        // Split the duration string into an array of time units
        formatted = durationObject.format("Y [years] M [months] D [days] H [hours] m [minutes] s [seconds]");
        timeUnits = formatted.match(/\b([1-9]\d*)\s*((?:year|month|day|hour|minute|second)s?)\b/g);
    }

    // Concatenate the time units into a single string, using the correct pluralization for each time unit
    let result = "";
    timeUnits?.forEach((timeUnit) => {
        if (timeUnit.startsWith("1 ")) {
            timeUnit = timeUnit.replace(/s?$/, "");
        }
        result += timeUnit + " ";
    });

    return result.trim();
}

export function convertToHours(seconds: number): string {
    const format = seconds >= 3600 ? "HH:mm:ss" : "mm:ss";
    return dayjs.duration(seconds * 1000).format(format);
}

export const convertHoursMinsToDec = (time: string): number => {
    const hours = parseInt(time.split(":")[0], 10);
    const minutes = parseInt(time.split(":")[1]) / 60;
    return hours + minutes;
};

export const isStringValidRegalDateFormat = (dateString: string) => {
    // first check if it is input that is valid to be a date
    const testDate = new Date(dateString);
    //@ts-expect-error TS doesnt like Date to Number but it works
    // returns "Invalid Date" if the date is not valid
    const dateCheck = !isNaN(testDate);
    // Then check if the dateString matches any of the formats we accept
    const isAcceptedFormat = acceptedRegalDateFormats.some((format) => format.test(dateString));
    // return true if both checks pass
    return isAcceptedFormat && dateCheck;
};
