import { gql } from "@apollo/client";

export const getCurrentBrand = gql`
    query {
        getBrand {
            id
            slug
            defaultFromNumber
            accountSid
            twilioConfig
            messagingConfig
            communicationSources {
                source
                internalDisplayName
                externalDisplayName
                subBrand
            }
            availableSkills {
                name
            }
            availableStatuses {
                name
                available
                activitySid
            }
            timezone
            businessHours
            brandSettings
        }
    }
`;
