import { useState, useCallback } from "react";

import { useQuery } from "@apollo/client";
import { Form, FormInstance, Input, Select, SelectProps } from "antd";
import { chain } from "lodash";
import { useSelector } from "react-redux";

import { useFlags } from "Services/FeatureFlagService";
import { getLogger } from "Services/LoggingService";
import { AGENTS_QUERY_HARD_LIMIT, getAgentsForInternalTransfer } from "Services/marketing-api/agents/queries";
import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";
import { Agent } from "Types/Agent";
import { QueryParams } from "Types/QueryParams";

import styles from "./AgentTransfer.module.scss";
const logger = getLogger("Twilio Conferences");

export const PROGRESSIVE_DIALER_AGENT_STATUS_NAME = "Progressive Dialer Mode";

/**
 * **isAvailable** says if the agent's current Twilio status (Offline, Lunch, etc.) is an available status or not
 *
 * **conferenceStatus** says if the agent is actively in a conference, regardless of their current Twilio status,
 */
export type AgentForTransfer = Pick<
    Agent,
    "twilioSid" | "name" | "twilioContactUri" | "isAvailable" | "status" | "conferenceStatus" | "email"
>;

export const PLACEHOLDER_TEXT = "Search by Agent Name or Email";

/**
 * @todo these should go some place global and be grouped with
 */
const availableStatus = "available";

const defaultQueryParams: QueryParams = {
    pageSize: AGENTS_QUERY_HARD_LIMIT,
    page: 0,
    orderBy: [
        {
            key: "name",
            value: "ASC",
        },
    ],
    filterBy: [],
};

interface AgentDropdownOption {
    name: string;
    label: JSX.Element;
    value: string;
    disabled: boolean;
}

export const filterNameAndEmail: NonNullable<SelectProps<AgentDropdownOption["value"]>["filterOption"]> = (
    inputValue,
    option
) => {
    const agentOption = option as AgentDropdownOption | undefined;
    const optionValue = agentOption?.value?.toLowerCase();
    const nameValue = agentOption?.name?.toLowerCase();
    if (!optionValue && !nameValue) {
        return false;
    }

    if (!inputValue) {
        return false;
    }
    const includes =
        (optionValue?.includes(inputValue.toLowerCase()) || nameValue?.includes(inputValue.toLowerCase())) ?? false;
    return includes;
};

type TransferItemProps = {
    agent: Partial<AgentForTransfer>;
};

export const TransferItem = ({ agent }: TransferItemProps): JSX.Element => {
    const { name, email, twilioContactUri } = agent;
    const title = `${name} (${email})`;

    return (
        <div data-twilio-uri={twilioContactUri} className={styles.transferItem} key={email} title={title}>
            <div className={styles.optionIdentifier}>
                <div className={styles.optionName} key={email}>
                    <span>{name}</span>
                </div>
                <span className={styles.muted}>{email}</span>
            </div>
        </div>
    );
};

type RenderItemProps = {
    agent: AgentForTransfer;
    enableTransferToBusyAgents: boolean;
};

export function renderItem({ agent, enableTransferToBusyAgents }: RenderItemProps): AgentDropdownOption {
    const { name, email, conferenceStatus, isAvailable, status } = agent;

    let isDisabled: boolean;
    if (status === PROGRESSIVE_DIALER_AGENT_STATUS_NAME) {
        isDisabled = true;
    } else {
        isDisabled = !isAvailable || conferenceStatus !== availableStatus;
        /**
         * If the agent is not available, but because they are on a call, and the flag is set
         * to allow transfers to busy agents, then we want to enable the agent.
         */
        if (isDisabled && enableTransferToBusyAgents && conferenceStatus !== availableStatus) {
            isDisabled = false;
        }
    }

    return {
        disabled: isDisabled,
        name,
        value: email,
        label: <TransferItem agent={agent} />,
    };
}

export function AgentInternalTransfer({ form }: { form: FormInstance }): JSX.Element {
    const [options, setOptions] = useState<AgentDropdownOption[]>();
    const currentWorkerUri = useSelector(selectWorkerUri);
    const { enableTransferToBusyAgents } = useFlags();

    /**
     * @IMPORTANT - This is a fetch not a search, so we are only ever getting the page size
     * and then filtering the results on the client side. This is not ideal, but it has working
     * for the last 2 years, and now we are bumping the size to 200, so it should continue to be ok for now.
     * We will need to figure out what out priority is for a true agent search.
     */
    useQuery(getAgentsForInternalTransfer, {
        notifyOnNetworkStatusChange: true, // necessary for onCompleted to run after refetch
        fetchPolicy: "no-cache",
        variables: { queryParams: defaultQueryParams },
        onCompleted({
            findAllAgents,
        }: {
            findAllAgents: {
                items: Array<AgentForTransfer>;
            };
        }) {
            if (!findAllAgents) {
                setOptions([]);
                return;
            }

            const { items: allAgents } = findAllAgents;

            const finalAgentDropDownOrder = chain(allAgents)
                .uniqBy("twilioContactUri")
                .filter(
                    (agent) =>
                        !!agent.twilioSid &&
                        !!agent.twilioContactUri &&
                        agent.twilioContactUri !== `client:${currentWorkerUri}`
                )
                .orderBy(["isActive", "isAvailable", "value"], ["desc", "desc", "asc"])
                .value();

            setOptions(finalAgentDropDownOrder.map((mi) => renderItem({ agent: mi, enableTransferToBusyAgents })));
        },
    });

    const handleAgentSelect = useCallback<NonNullable<SelectProps<AgentDropdownOption["value"]>["onChange"]>>(
        (_, option) => {
            const agentOption = option as AgentDropdownOption;
            const optionLabel = agentOption.label;
            const targetWorkerUri = optionLabel.props["agent"]["twilioContactUri"];
            logger.log("Tranfering contact to agent", {
                transferAgentUri: targetWorkerUri,
            });
            form.setFieldsValue({
                targetWorkerUri,
            });
        },
        [form]
    );

    return (
        <>
            <Form.Item
                name="targetWorkerUri"
                rules={[{ required: true, message: "The field is required" }]}
                style={{
                    display: "none",
                }}
            >
                <Input autoFocus placeholder={PLACEHOLDER_TEXT} size="large" />
            </Form.Item>
            <Form.Item name="internalAgentName" rules={[{ required: true, message: "The field is required" }]}>
                <Select
                    placeholder={PLACEHOLDER_TEXT}
                    showSearch
                    options={options}
                    onChange={handleAgentSelect}
                    optionLabelProp="name"
                    size="large"
                    filterOption={filterNameAndEmail}
                />
            </Form.Item>
        </>
    );
}
