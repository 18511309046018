import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";

import type { RootReducerShape } from "Services/state/Storage";
import type { LocalNodeData } from "Types/IVR";

/**
 * @typedef IVRNodeData
 * @property {string[]} contactPhones - All the phones that the IVR has access to
 * @property {boolean} isFormDirty - If we have made changes to the form and need to save
 * @property {Record<string, object>} nodeData - This is just a big json blob of all the nodes and their data. We don't manage individual nodes or properties in the redux store
 * @property {number | string} friendlyId - The next friendly id to use when adding a new node
 */
interface IVRNodeData {
    contactPhones: string[];
    isFormDirty: boolean;
    nodeData: Record<string, object>;
    friendlyId: number | string;
}

export const initialState: IVRNodeData = {
    contactPhones: [],
    isFormDirty: false,
    nodeData: {},
    friendlyId: 1,
};
// This is really the IVR Editor state not just the node data
// Rename when we have time
const IVRNodeDataSlice = createSlice({
    name: "IVRNodeData",
    initialState,
    reducers: {
        // these are the phones that the IVR has access to, we need this for the trigger node
        setContactPhones: (state, action: PayloadAction<string[]>) => {
            state.contactPhones = action.payload;
        },

        setIfFormDirty: (state, action: PayloadAction<boolean>) => {
            state.isFormDirty = action.payload;
        },

        setInitialNodeFormData: (state, action: PayloadAction<{ nodeData: Record<string, object> }>) => {
            const { nodeData } = action.payload;
            state.nodeData = nodeData;
            // loop through the nodes and get the largest friendly ID and set it
            let friendly = 0;
            const keys = Object.keys(nodeData);
            keys.forEach((key) => {
                const testId = (nodeData[key] as LocalNodeData).friendlyId;
                if (testId > friendly) {
                    friendly = testId;
                }
            });
            state.friendlyId = friendly;
        },

        clearNodeState: (state) => {
            state.contactPhones = [];
            state.isFormDirty = false;
            state.nodeData = {};
            state.friendlyId = 1;
        },

        // when we add a new node we make a place and id for it.
        addNodeDataItem: (state, action: PayloadAction<{ key: string; value: object }>) => {
            const friendlyInt = parseInt(state.friendlyId as string, 10);
            const friendlyId = friendlyInt + 1;
            state.nodeData[action.payload.key] = {
                ...action.payload.value,
                friendlyId,
            };
            state.friendlyId = friendlyId;
        },

        removeNodeDataItem: (state, action: PayloadAction<string>) => {
            delete state.nodeData[action.payload];
        },

        // use our current data and overwrite the values we pass in
        setNodeDataItem: (state, action: PayloadAction<{ key: string; value: object }>) => {
            // @ts-expect-error get better types
            const label = state.nodeData[action.payload.key]?.label;
            // @ts-expect-error get better types
            const friendlyId = state.nodeData[action.payload.key]?.friendlyId;

            state.nodeData[action.payload.key] = {
                ...action.payload.value,
                label,
                friendlyId,
            };
        },
    },
});

export const {
    setContactPhones,
    setIfFormDirty,
    clearNodeState,
    setInitialNodeFormData,
    addNodeDataItem,
    removeNodeDataItem,
    setNodeDataItem,
} = IVRNodeDataSlice.actions;

const selectIVRNodeData = (state: RootReducerShape) => state.IVRNodeData;
export const selectContactPhones = createSelector(selectIVRNodeData, (state) => state.contactPhones);
export const selectFormDirty = createSelector(selectIVRNodeData, (state) => state.isFormDirty);
export const selectAllNodesData = createSelector(selectIVRNodeData, (state) => state.nodeData);

// a selector that returns a function that takes in a node id and returns the node data
export const selectNodeDataForId = createSelector(selectAllNodesData, (nodes) => (id: string) => nodes[id]);

export default IVRNodeDataSlice.reducer;
