import { useMemo } from "react";

import { useSelector } from "react-redux";

import { formatPhoneNumber } from "Services/CommunicationService";
import { selectTaskContact } from "Services/state/contacts/Selectors/taskSelectors";

import type { ParticipantInfo } from "Services/state/conferences";

export type UseMainParticipantOptions = {
    participants: ParticipantInfo[];
};

export type UseMainParticipantResult = {
    participant: ParticipantInfo;
    name?: string;
    phoneNumber: string;
};

export const useMainParticipant = ({
    participants,
}: UseMainParticipantOptions): UseMainParticipantResult | undefined => {
    const participant = participants.find(({ phoneNumber }) => {
        if (!phoneNumber) {
            return false;
        }

        return !phoneNumber.startsWith("client:");
    });

    const contact = useSelector(selectTaskContact)(participant?.phoneNumber || "");
    return useMemo(() => {
        if (!participant) {
            return;
        }

        const name = contact?.name || participant.name;
        const phoneNumber = formatPhoneNumber(participant.phoneNumber);
        return {
            participant,
            name,
            phoneNumber,
        };
    }, [participant, contact]);
};
