import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getLogger } from "Services/LoggingService";

import { selectBrandAvailableStatuses } from "../brand/selectors";
import { RootState } from "../Storage";

const logger = getLogger("Agent State");

const GmailAuthStatusOptions = {
    connected: "CONNECTED",
    disconnected: "DISCONNECTED",
} as const;

export type GmailIntegration = {
    authorizationStatus: (typeof GmailAuthStatusOptions)[keyof typeof GmailAuthStatusOptions];
    createdAt: string;
    emailAddress: string;
};

export type AgentInformationState = {
    phoneNumber?: string;
    name?: string;
    email?: string;
    workerSid?: string | null;
    workerUri?: string;
    onActiveCall?: boolean;
    brandId: string | null;
    status?: string;
    statusChangedAt?: string;
    attributes?: Record<string, any>;
    preferences?: Record<string, any>;
    gmailIntegration?: Array<GmailIntegration>;
};

export type AgentActivity = {
    available: boolean;
    name: string;
    id: string;
    startTime: number;
};

export type SetDefaultPhoneNumberPayload = {
    phoneNumber: string;
};

export type SetAgentActivityPayload = {
    status: string;
    statusChangedAt: string;
};

export type SetAgentDataPayload = {
    phoneNumber?: string;
    email?: string;
    workerSid?: string | null;
    workerUri?: string;
    status?: string;
    statusChangedAt?: string;
    attributes?: Record<string, any>;
    name?: string;
    gmailIntegration?: Array<GmailIntegration>;
    preferences?: Record<string, any>;
};

export type SetPreferencesPayload = {
    preferences: Record<string, any>;
};

type SetWorkerIdsPayload = {
    workerSid: string;
    workerUri: string;
};

const initialState: AgentInformationState = {
    brandId: null,
};

const agentInformationSlice = createSlice({
    name: "agentInformation",
    initialState,
    reducers: {
        setDefaultPhoneNumber: (state: AgentInformationState, action: PayloadAction<SetDefaultPhoneNumberPayload>) => {
            state.phoneNumber = action.payload.phoneNumber;
        },
        setAgentData: (state: AgentInformationState, action: PayloadAction<SetAgentDataPayload>) => {
            return { ...state, ...action.payload };
        },
        setWorkerIds: (state: AgentInformationState, action: PayloadAction<SetWorkerIdsPayload>) => {
            state.workerSid = action.payload.workerSid;
            state.workerUri = action.payload.workerUri;
        },
        setOnActiveCall: (state: AgentInformationState, action: PayloadAction<boolean>) => {
            state.onActiveCall = action.payload;
        },
        setBrandId: (state: AgentInformationState, action: PayloadAction<{ brandId: string }>) => {
            if (!state.brandId || state.brandId == action.payload.brandId) {
                state.brandId = action.payload.brandId;
            } else {
                return { brandId: action.payload.brandId };
            }
        },
        setAgentActivity: (state: AgentInformationState, action: PayloadAction<SetAgentActivityPayload>) => {
            state.status = action.payload.status;
            state.statusChangedAt = action.payload.statusChangedAt;
        },
        setAgentPreferences: (state: AgentInformationState, action: PayloadAction<SetPreferencesPayload>) => {
            state.preferences = action.payload.preferences;
        },
    },
});

export const {
    setDefaultPhoneNumber,
    setAgentData,
    setWorkerIds,
    setOnActiveCall,
    setBrandId,
    setAgentActivity,
    setAgentPreferences,
} = agentInformationSlice.actions;

export default agentInformationSlice.reducer;

// selectors
export const selectAgentInformation = (state: RootState): AgentInformationState => state.agentInformation;
// ... rest of your selectors
export const selectAgentName = createSelector(selectAgentInformation, (agentInfo) => {
    return agentInfo.name;
});
export const selectOnActiveCall = createSelector(selectAgentInformation, (agentInfo) => {
    return !!agentInfo.onActiveCall;
});
export const selectWorkerSid = createSelector(selectAgentInformation, (agentInfo) => {
    return agentInfo.workerSid;
});
export const selectAgentEmail = createSelector(selectAgentInformation, (agentInfo) => {
    return agentInfo.email;
});
export const selectAgentBrandId = createSelector(selectAgentInformation, (agentInfo) => {
    return agentInfo.brandId;
});
export const selectAgentQueryParams = createSelector([selectWorkerSid, selectAgentEmail], (twilioWorkerSid, email) => {
    return {
        twilioWorkerSid,
        email,
    };
});

export const selectAgentActivity = createSelector(
    selectAgentInformation,
    selectBrandAvailableStatuses,
    (agentInfo, statuses): AgentActivity => {
        if (!statuses.length) {
            // The brand hasn't been loaded yet.
            return {
                available: false,
                id: "",
                name: "Offline",
                startTime: Date.now(),
            };
        }

        const status = statuses.find((item) => item.name == agentInfo.status);
        if (status) {
            return {
                available: status.available,
                id: status.activitySid,
                name: status.name,
                startTime: agentInfo.statusChangedAt ? new Date(agentInfo.statusChangedAt).getTime() : Date.now(),
            };
        }

        const offlineStatus = statuses.find((item) => !item.available);
        if (offlineStatus) {
            logger.warn(
                `Agent status '${agentInfo.status}' not found in available statuses - using '${offlineStatus.name}' instead`
            );
            return {
                available: false,
                name: offlineStatus.name,
                id: offlineStatus.activitySid,
                startTime: Date.now(),
            };
        }

        logger.warn(
            `Agent status '${agentInfo.status}' not found in available statuses - using default offline status`
        );

        return {
            available: false,
            id: "",
            name: "Offline",
            startTime: Date.now(),
        };
    }
);

// This is currently only used for a single gmail integration
// In the future, we will need to support multiple gmail integrations
// We are sorting the list just to be extra safe and deterministic.
export const selectAgentGmailIntegration = createSelector(selectAgentInformation, (agentInformation) => {
    if (typeof agentInformation.gmailIntegration == "undefined" || agentInformation.gmailIntegration.length === 0) {
        return undefined;
    } else {
        return Array.from(agentInformation.gmailIntegration).sort((a, b) => {
            // always return the oldest record for consistency. update this once we support multiple gmail integrations
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        })[0];
    }
});

export const selectAgentIsGmailConnected = createSelector(selectAgentGmailIntegration, (gmailIntegration) => {
    return !!gmailIntegration && gmailIntegration?.authorizationStatus === GmailAuthStatusOptions.connected;
});

export const selectWorkerUri = createSelector(selectAgentInformation, (agentInfo) => agentInfo.workerUri);
export const selectPreferences = createSelector(selectAgentInformation, (agentInfo) => agentInfo.preferences || {});
export const selectAgentPreferredEdge = createSelector(
    selectAgentInformation,
    (agentInfo) => agentInfo.attributes?.preferredEdge
);
