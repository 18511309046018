import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";

import { RootState } from "Services/state/Storage";

export const AgentDesktopSubPages = {
    TASKS_PAGE: "tasks",
    CONTACT_PAGE: "contacts",
} as const;

export type SubPagesOptions = (typeof AgentDesktopSubPages)[keyof typeof AgentDesktopSubPages];

export type ActivityFeedConversationFilter = {
    conversations: boolean;
    events: boolean;
};

export const DEFAULT_CONVERSATION_FILTERS: ActivityFeedConversationFilter = {
    conversations: true,
    events: true,
};

export type ADUIState = {
    subPage: SubPagesOptions;
    contactSearchTerm: string;
    conversationFilter?: ActivityFeedConversationFilter;
};

const initialState: ADUIState = {
    // the page we are looking at
    subPage: AgentDesktopSubPages.TASKS_PAGE,
    // search term for the contacts panel
    contactSearchTerm: "",
    conversationFilter: DEFAULT_CONVERSATION_FILTERS,
};

const AgentDesktopUISlice = createSlice({
    name: "agentDesktopUI",
    initialState,
    reducers: {
        setSubPage(state: ADUIState, action: PayloadAction<SubPagesOptions>) {
            state.subPage = action.payload;
        },
        setContactSearchTerm(state: ADUIState, action: PayloadAction<string>) {
            state.contactSearchTerm = action.payload;
        },
        setConversationFilter(state: ADUIState, action: PayloadAction<ActivityFeedConversationFilter>) {
            state.conversationFilter = action.payload;
        },
    },
});

export interface Attachment {
    type: string;
    name: string;
    media: Buffer;
    size: number;
    localUrl: string;
}

export const { setSubPage, setContactSearchTerm, setConversationFilter } = AgentDesktopUISlice.actions;

export default AgentDesktopUISlice.reducer;

export const AgentDesktopUISliceSelector = (state: RootState): ADUIState => state.agentDesktopUI;

export const selectADSubPage = createSelector(AgentDesktopUISliceSelector, (agentDesktopUI) => agentDesktopUI.subPage);

export const selectADContactSearchTerm = createSelector(
    AgentDesktopUISliceSelector,
    (agentDesktopUI) => agentDesktopUI.contactSearchTerm
);

export const selectIsTasksPage = createSelector(
    selectADSubPage,
    (subPage) => AgentDesktopSubPages.TASKS_PAGE === subPage
);

export const selectIsContactsPage = createSelector(
    selectADSubPage,
    (subPage) => AgentDesktopSubPages.CONTACT_PAGE === subPage
);

// Features
export const shouldShowScheduleButtonOnActivityView = createSelector(
    selectIsContactsPage,
    (isContactsPage) => isContactsPage
);

export const selectShowInfoSidebar = createSelector(selectIsTasksPage, (isTasksPage) => isTasksPage);

export const selectADConversationFilter = createSelector(
    AgentDesktopUISliceSelector,
    (agentDesktopUI) => agentDesktopUI.conversationFilter ?? DEFAULT_CONVERSATION_FILTERS
);
