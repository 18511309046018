import { Route, Redirect } from "react-router-dom";

import { routes } from "App/routes";
import { InternalAdminPages } from "Pages/admin";
import { PhoneNumbersPages } from "Pages/phone-numbers";
import { SettingsPages } from "Pages/settings";
import { TranscriptsPage } from "Pages/transcripts";

type RouteProps = React.ComponentProps<typeof Route>;
type SecureRouteComponent = React.ComponentType<Pick<RouteProps, "path" | "component" | "exact">>;

type AppRoutesProps = {
    secureRouteComponent: SecureRouteComponent;
};

const pathToKey = (path: string | string[]): string => {
    if (Array.isArray(path)) {
        return path.join("-");
    }

    return path;
};

export default function getAppRoutes({ secureRouteComponent: SecureRoute }: AppRoutesProps): JSX.Element[] {
    return [
        <SecureRoute key="/admin" path="/admin" component={InternalAdminPages} />,

        <SecureRoute key="/settings" path="/settings" component={SettingsPages} />,

        <SecureRoute key="/transcripts" path="/transcripts" component={TranscriptsPage} />,

        <SecureRoute key="/phone-numbers" path="/phone-numbers" component={PhoneNumbersPages} />,

        ...routes.map((route) => <SecureRoute key={pathToKey(route.path)} {...route} />),

        <Route key="fallback" exact path="/">
            <Redirect to="/agent" />
        </Route>,
    ];
}
