import { useState } from "react";

import RejectTaskOptions from "Pages/agentDesktop/agentInterface/shared/RejectTaskOptions/RejectTaskOptions";

import { BUTTONS_MESSAGES } from "../CallBar.consts";
import { CallBarButton } from "./CallBarButton";

import type { RVTask } from "@regal-voice/shared-types";

import styles from "../CallBar.module.scss";

export type IncomingCallBarProps = {
    activeTask?: RVTask;
    acceptingCall: boolean;
    acceptActiveTask: () => void;
};

export function IncomingCallBarButtons({
    activeTask,
    acceptActiveTask,
    acceptingCall,
}: IncomingCallBarProps): JSX.Element {
    const [rejectLoading, setRejectLoading] = useState(false);

    return (
        <ul className={styles.buttonsList}>
            <li key="accept">
                <CallBarButton
                    id="accept"
                    active={false}
                    action={acceptActiveTask}
                    icon="accept"
                    messages={BUTTONS_MESSAGES.accept}
                    className={styles.accept}
                    loading={acceptingCall}
                />
            </li>
            <li key="reject">
                <RejectTaskOptions
                    onLoadStateChange={setRejectLoading}
                    task={activeTask}
                    dropdownPlacement="top"
                    tooltipPlacement="top"
                >
                    <CallBarButton
                        id="reject"
                        active={false}
                        icon="close"
                        className={styles.reject}
                        loading={rejectLoading}
                    />
                </RejectTaskOptions>
            </li>
        </ul>
    );
}
