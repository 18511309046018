import { memo, Suspense, useEffect, useMemo } from "react";

import { ApolloProvider } from "@apollo/client";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CompatRouter, useSearchParams } from "react-router-dom-v5-compat";

import { ErrorBoundary } from "App/ErrorBoundary";
import NotFound from "App/NotFound";
import { routerBaseName } from "App/routes";
import { VersionChecker } from "App/VersionChecker";
import { setLDClient } from "Application/thirdParty/launchDarkly";
import { Loading, UserNotifier } from "Components/elements";
import Layout from "Components/layouts/Layout";
import { TwilioDeviceProvider } from "Hooks/useTwilioDevice/useTwilioDevice";
import { getApolloClient } from "Services/ApolloClientService";
import { useTasksOpenUrlSettingsLoader } from "Services/TasksOpenUrlSettings";
import { initializeConversations, conversationsClient } from "Services/TwilioConversations.service";
import { interceptUnauthorizedResponses } from "Services/util/NetworkUtilService";
import { CollapsiblePanelsProvider } from "Utils/CollapsiblePanels";

import getAppRoutes from "./getAppRoutes";
import SecureRoute from "./SecureRoute";
import { useFetchInterceptor } from "./useFetchInterceptor";
import UserProvider from "./UserProvider";
import { useSessionValidityCheck } from "./useSessionValidityCheck";
import useThirdPartyScripts from "./useThirdPartyScripts";

function AppContainer() {
    const [searchParams, setSearchParams] = useSearchParams();
    useThirdPartyScripts();
    useTasksOpenUrlSettingsLoader();

    const ldClient = useLDClient();

    if (ldClient) {
        setLDClient(ldClient);
    }

    // set up unauthenticated request management
    useFetchInterceptor(interceptUnauthorizedResponses);

    useSessionValidityCheck();

    useEffect(() => {
        initializeConversations();
        return () => {
            conversationsClient?.shutdown();
        };
    }, []);

    useEffect(() => {
        // we remove the cache buster param from the URL if it exists
        // we add it upon login redirection to prevent caching
        if (searchParams.has("t")) {
            searchParams.delete("t");
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    const appRoutes = useMemo(() => getAppRoutes({ secureRouteComponent: SecureRoute }), []);

    return (
        <ApolloProvider client={getApolloClient()}>
            <UserNotifier />
            <TwilioDeviceProvider>
                <UserProvider>
                    <CollapsiblePanelsProvider>
                        <VersionChecker />
                        <Layout pageReady>
                            <ErrorBoundary>
                                <Suspense fallback={<Loading />}>
                                    <Switch>
                                        {appRoutes}
                                        <Route>
                                            <NotFound />
                                        </Route>
                                    </Switch>
                                </Suspense>
                            </ErrorBoundary>
                        </Layout>
                    </CollapsiblePanelsProvider>
                </UserProvider>
            </TwilioDeviceProvider>
        </ApolloProvider>
    );
}

const AppContainerWrapper = memo(AppContainer);
AppContainerWrapper.displayName = "AppContainer";

export default function AppWithRouterAccess(): JSX.Element {
    return (
        <Router basename={routerBaseName}>
            <CompatRouter>
                <AppContainerWrapper />
            </CompatRouter>
        </Router>
    );
}
