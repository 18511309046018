import { useContext, useEffect, useState } from "react";

import { ParticipantCallInfo, ParticipantCallStatus, TransferInfo } from "@regal-voice/shared-types";
import dayjs from "dayjs";
import { isEmpty, omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { UserContext } from "App/contexts";
import { useServerSentEvent } from "Hooks/useServerSentEvent";
import { useFlags } from "Services/FeatureFlagService";
import { getLogger } from "Services/LoggingService";
import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";
import { clearConference, ParticipantInfo, setConference } from "Services/state/conferences/ConferenceStateSlice";
import { selectActiveConferenceTask, selectConference } from "Services/state/conferences/Selectors";
import { CallStatusEvent } from "Services/Task.service";

import { FailedCallMessages, FailStatus, showFailedCallToast } from "../helpers";

const logger = getLogger("Conference Participants Events");

const DISCONNECTED_STATUSES = ["completed", "no-answer", "busy", "failed", "canceled"];
const FAILED_STATUSES = Object.keys(FailedCallMessages);

export interface ConferenceParticipant {
    name: string;
    contactPhone: string;
    regalVoicePhone: string;
    deviceCallSid?: string;
}

type callSidType = string;
type eventRecord = Record<callSidType, ParticipantCallInfo>;

// helper function to properly set the record when someone is actually joining
export function handleSetEventForJoined(
    prevValue: eventRecord,
    event: any,
    participantEventRecord: eventRecord
): eventRecord {
    // This logic allows the hold status display to work for a local hold that an agent applied before the contact picks up
    // as well as a warm transfers where the accepting agent doesnt have a hold status
    // If we know the hold status we want to maintain it, otherwise use the event's version.
    const holdStatus = participantEventRecord[event.callInfo.callSid]?.onHold ?? event.callInfo.onHold;

    const infoObj = {
        ...event.callInfo,
        onHold: holdStatus,
    };
    const eventObj = {
        ...omit(prevValue, [event.callInfo.callSid, event.callInfo.to, event.callInfo.from]),
        [event.callInfo.callSid]: infoObj,
    };
    return eventObj;
}

// helper function to properly set the record when someone isn't actually "joining"
export function handleSetEventForNotJoined(prevValue: eventRecord, event: any): eventRecord {
    const ommited = { ...omit(prevValue, [event.callInfo.callSid]) };
    return ommited;
}

export function useConferenceParticipants({
    name,
    contactPhone,
    regalVoicePhone,
    deviceCallSid,
}: ConferenceParticipant) {
    const dispatch = useDispatch();
    const { brand } = useContext(UserContext);
    const [participantsEvents, setParticipantsEvents] = useState<eventRecord>({});
    const { conferenceFriendlyId, participants, connectionParams } = useSelector(selectConference);
    const currentWorkerUri = useSelector(selectWorkerUri);
    const activeConferenceTask = useSelector(selectActiveConferenceTask);
    const { multipleCalls } = useFlags();

    useServerSentEvent("twilio", "participant.joined", handleParticipantJoinedEvent);
    useServerSentEvent("twilio", ["transfer.initiated", "transfer.rejected"], handlePendingTransferEvent);
    useServerSentEvent("twilio", "participant.status", handleParticipantStatusChange);

    function handleParticipantStatusChange(event: { callStatus: ParticipantCallStatus }) {
        logger.log("SSE received participant.status event", {
            event,
        });
        setParticipantsEvents((prevValue) => ({
            ...prevValue,
            // bug bash clean up, i think this is just sloppy typing
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [event.callStatus!.callSid]: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...prevValue[event.callStatus!.callSid],
                onHold: event.callStatus.onHold,
                ...(event.callStatus.managerAction ? { managerAction: event.callStatus.managerAction } : {}),
            },
        }));
    }

    function handlePendingTransferEvent(event: { transferInfo: TransferInfo }) {
        logger.log("SSE received transfer.initiated event", {
            event,
        });
        if (
            event.transferInfo.notificationType == "rejected" &&
            event.transferInfo.conferenceFriendlyId === conferenceFriendlyId
        ) {
            setParticipantsEvents((prevValue: { [callSid: string]: ParticipantCallInfo }) => ({
                ...omit(prevValue, [event.transferInfo.agentContactUri || event.transferInfo.taskSid]),
            }));
            return;
        }

        setParticipantsEvents((prevValue: { [callSid: string]: ParticipantCallInfo }) => ({
            ...prevValue,
            [`${event.transferInfo.agentContactUri || event.transferInfo.taskSid}`]: {
                conferenceFriendlyId: event.transferInfo.conferenceFriendlyId,
                conferenceSid: "",
                callSid: `${event.transferInfo.agentContactUri}`,
                status: "",
                sequenceNo: "0",
                to: event.transferInfo.agentContactUri,
                from: "",
                startTimestamp: "",
                taskSid: event.transferInfo.taskSid,
                queueName: event.transferInfo.queueName,
            } as ParticipantCallInfo,
        }));
    }

    function checkShouldDeleteQueuePlaceholder(event: { callInfo: ParticipantCallInfo }) {
        if (participantsEvents[event.callInfo.taskSid]) {
            setParticipantsEvents(omit(participantsEvents, event.callInfo.taskSid));
        }
    }

    function handleParticipantJoinedEvent(event: { callInfo: ParticipantCallInfo }) {
        logger.log("SSE received participant.joined event", {
            event,
            currentWorkerUri,
        });

        const hasParticipantJoined = !DISCONNECTED_STATUSES.includes(event.callInfo.status);

        if (FAILED_STATUSES.includes(event.callInfo.status)) {
            logger.warn(`Call failed with status: ${event.callInfo.status}`);
            showFailedCallToast(event.callInfo.status as FailStatus);
        }

        if (hasParticipantJoined) {
            checkShouldDeleteQueuePlaceholder(event);
            setParticipantsEvents((prevValue: eventRecord) => {
                return handleSetEventForJoined(prevValue, event, participantsEvents);
            });
        } else {
            setParticipantsEvents((prevValue: eventRecord) => {
                return handleSetEventForNotJoined(prevValue, event);
            });
        }
    }

    // start counter for every added call only when all the other participants are connected
    function getInboundCallStartedTimestamp(
        participantsArray: ParticipantCallInfo[],
        participant: ParticipantCallInfo
    ): number | undefined {
        // bug bash clean up, we should really know about the participant
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (participants[participant.callSid!]?.startTimestamp) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return participants[participant.callSid!]!.startTimestamp;
        }

        const allParticipantsConnected = participantsArray.every(
            (p) => p.status == "in-progress" || DISCONNECTED_STATUSES.includes(p.status as string)
        );
        if (allParticipantsConnected) {
            return dayjs().unix();
        }
    }

    function getParticipantType(isContact: boolean, phoneNumber: string) {
        if (isContact) {
            return "contact";
        } else if (phoneNumber?.includes("client:") && phoneNumber !== `client:${currentWorkerUri}`) {
            return "internal";
        }
        return "external";
    }

    function getParticipantName(isContact: boolean, name: string, phoneNumber: string) {
        if (isContact) {
            return name;
        } else {
            return phoneNumber;
        }
    }

    /**
     * Goes through the participants and if they belong to the current conference, it maps them in a key-value format.
     * If the feature flag is on and we have an active task with conference friendly ID, we use that (the most accurate).
     * Listen/barge will not have an active task, so it should fall back to the conference friendly ID that we store ourselves.
     */
    function mapParticipants(participantsArray: ParticipantCallInfo[]) {
        const currentFriendlyIdToUse =
            multipleCalls && activeConferenceTask?.attributes.conferenceFriendlyId
                ? activeConferenceTask?.attributes.conferenceFriendlyId
                : conferenceFriendlyId;
        return participantsArray.reduce(
            (acc: { [callSid: string]: ParticipantInfo }, participant: ParticipantCallInfo) => {
                if (currentFriendlyIdToUse && participant.conferenceFriendlyId !== currentFriendlyIdToUse) {
                    logger.log("Found participant not belonging to the current conference", {
                        newParticipantInSSE: participant,
                        conferenceFriendlyId,
                        activeConferenceTask,
                        connectionParams,
                    });
                    // in case somehow any participants leftovers, ignore them
                    return acc;
                }
                const participantUri = participant.to !== regalVoicePhone ? participant.to : participant.from;
                const isContact = participantUri === contactPhone;
                // bug bash clean up, we should really know about the participant
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                acc[participant.callSid!] = {
                    conferenceFriendlyId: participant.conferenceFriendlyId,
                    conferenceSid: participant.conferenceSid,
                    callSid: participant.callSid,
                    phoneNumber: participantUri,
                    status: participant.status as CallStatusEvent,
                    startTimestamp: getInboundCallStartedTimestamp(participantsArray, participant),
                    // bug bash clean up, we should have a name fallback
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    name: getParticipantName(isContact, name!, participantUri),
                    type: getParticipantType(isContact, participantUri),
                    onHold: participant.onHold,
                    from: participant.from,
                    to: participant.to,
                    sequenceNo: participant.sequenceNo,
                    taskSid: participant.taskSid,
                    queueName: participant.queueName,
                    ...(participant?.managerAction ? { managerAction: participant.managerAction } : {}),
                };
                return acc;
            },
            {}
        );
    }

    function updateConferenceOnEventReceived() {
        const participantsArray = Object.values(participantsEvents) || ({} as any);
        const filteredParticipantsObj = mapParticipants(participantsArray);
        const filteredParticipantsArray = Object.values(filteredParticipantsObj);
        const participantsConnected =
            filteredParticipantsArray.filter((p: any) => p.status == CallStatusEvent.IN_PROGRESS)?.length > 1;
        if (!filteredParticipantsArray?.length) {
            if (!deviceCallSid && (activeConferenceTask?.status === "wrapping" || !activeConferenceTask)) {
                logger.log("Clearing conference state", {
                    conferenceFriendlyId,
                });
                dispatch(clearConference());
            }
            return;
        }
        const connectedParticipant = (multipleCalls ? filteredParticipantsArray : participantsArray).find(
            (p) => p.conferenceSid || p.conferenceFriendlyId
        ) as ParticipantCallInfo;

        // Debug log
        // NOTE: I strongly suspect this was a mistake on our part
        //       in assuming that we would ever want to early exit here
        //       when on a call and accepting another call.
        //       Instead we should have disconnected the previous call.
        Object.values(filteredParticipantsObj).forEach((participant) => {
            const isUnexpectedParticipant =
                participant.conferenceFriendlyId !== activeConferenceTask?.attributes?.conferenceFriendlyId;
            if (isUnexpectedParticipant) {
                logger.warn(
                    "Debug: Participant doesn't belong to the active conference, don't update the conference.",
                    {
                        activeConferenceTask,
                        conferenceFriendlyId,
                        connectedParticipant,
                        participant,
                        newParticipantsObj: filteredParticipantsObj,
                        participantsConnected,
                        isRecording: !!brand?.twilioConfig?.callRecordingEnabled,
                    }
                );
            }
        });

        dispatch(
            setConference({
                conferenceFriendlyId: connectedParticipant?.conferenceFriendlyId,
                conferenceSid: connectedParticipant?.conferenceSid,
                participants: filteredParticipantsObj,
                connected: participantsConnected,
                isRecording: !!brand?.twilioConfig?.callRecordingEnabled,
            })
        );
    }

    useEffect(() => {
        if (isEmpty(participants) || !conferenceFriendlyId) {
            const initialParticipants = Object.assign({}, participants);
            const initialConferenceFriendlyId = conferenceFriendlyId;

            // trying to get rid of the 'ended' call race condition
            if (isEmpty(participants) && !conferenceFriendlyId) {
                // debug log
                if (!isEmpty(initialParticipants) || initialConferenceFriendlyId) {
                    logger.warn("Debug: we're about to clear the participants events", {
                        initialParticipants,
                        initialConferenceFriendlyId,
                        participants,
                        conferenceFriendlyId,
                    });
                }
                logger.log("Clearing participants events");
                setParticipantsEvents({});
            }
        }
    }, [participants, conferenceFriendlyId]);

    useEffect(() => {
        updateConferenceOnEventReceived();
    }, [
        Object.values(participantsEvents || {})
            .map((i: any) => `${i.callSid}-${i.status}-${i.onHold}${i.managerAction ? "-" + i.managerAction : ""}`)
            .join(","),
        deviceCallSid,
        contactPhone,
        conferenceFriendlyId,
        activeConferenceTask,
        multipleCalls,
    ]);

    return {
        participantsEvents,
    };
}
