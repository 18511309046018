import { useEffect, useMemo, useRef, useState } from "react";

import { RVTask } from "@regal-voice/shared-types";
import { clsx } from "clsx";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import Icon, { IconSizes, IconVariants } from "Components/elements/Icon";
import { formatPhoneNumber } from "Services/CommunicationService";
import { dropParticipant, holdParticipant } from "Services/ConversationsApiService";
import { selectConference } from "Services/state/conferences";
import { ParticipantInfo, setConferenceDuration } from "Services/state/conferences/ConferenceStateSlice";
import { selectTaskContact } from "Services/state/contacts/Selectors/taskSelectors";
import { CallStatusEvent, isPendingInboundConferenceTask } from "Services/Task.service";
import { Agent } from "Types/Agent";

import CallActionButton from "../../ActiveCall/CallActionButton/CallActionButton";

import styles from "./CallParticipant.module.scss";

const messages = {
    hold: {
        title: (isActive: boolean) => (isActive ? "Unhold" : "Hold"),
        success: (isActive: boolean) => `Call ${isActive ? "on hold" : "resumed"}`,
        error: (err: string, isActive?: boolean) =>
            `An error occured while ${isActive ? "pausing" : "resuming"} the recoding - ${err}`,
    },
    hangUp: {
        title: () => "Hang Up",
        error: (err: string) => `An error occured while wrapping the call - ${err}`,
    },
};

export function CallParticipant({
    agents,
    currentWorkerUri,
    participant,
    task,
}: {
    agents: Agent[];
    currentWorkerUri: string;
    participant: ParticipantInfo;
    task: RVTask;
}): JSX.Element {
    const interval = useRef(0);
    const [callStatus, setCallStatus] = useState<string>();
    const { participants, duration, connectionParams } = useSelector(selectConference);

    const dispatch = useDispatch();

    const shouldDisplayIndependentActionButtons = useMemo(
        () =>
            participants &&
            Object.values(participants).filter(
                (p) => p.phoneNumber != `client:${currentWorkerUri}` && p.managerAction !== "listen"
            ).length >= 2,
        [participants, currentWorkerUri]
    );

    const isHoldDisabled = Boolean(participant?.managerAction);
    const isHangUpDisabled = Boolean(participant?.managerAction);

    const isContact = participant?.type == "contact";
    const isAgent = participant.phoneNumber?.includes("client:");
    const isExternalPhonebook = participant?.type == "external-phonebook";
    // only needed when the participant is the contact being reached out to
    const contact = useSelector(selectTaskContact)(participant.phoneNumber || "");

    const participantConnected = participant.status == CallStatusEvent.IN_PROGRESS;

    const formattedTime = useMemo(() => {
        // we should really know if we have the participant or not
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const h = Math.floor(((dayjs().unix() - participant!.startTimestamp!) % (3600 * 24)) / 3600);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const m = Math.floor(((dayjs().unix() - participant!.startTimestamp!) % 3600) / 60);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const s = Math.floor((dayjs().unix() - participant!.startTimestamp!) % 60);
        const prepandZeroToTime = (value: number) => {
            return value < 10 ? `0${value}` : value;
        };
        return `${h ? `${prepandZeroToTime(h)}:` : ""}${m ? prepandZeroToTime(m) : "00"}:${
            s ? prepandZeroToTime(s) : "00"
        }`;
    }, [duration]);

    function holdWrap(onHold: boolean) {
        return holdParticipant({
            conferenceSid: participant.conferenceSid as string,
            callSid: participant.callSid as string,
            hold: !onHold,
        });
    }

    function dropParticipantByCallSid() {
        return dropParticipant({
            conferenceSid: participant.conferenceSid as string,
            callSid: participant.callSid as string,
            taskSid: connectionParams.taskSid as string,
        });
    }

    function getDefaultConnectionStatus() {
        return isPendingInboundConferenceTask(task) ? "Ringing..." : "Connecting...";
    }

    const participantTypePlaceholder = useMemo(() => {
        if (isContact) {
            return formatPhoneNumber(participant.phoneNumber);
        } else if (participant?.managerAction === "barge") {
            return "Barging";
        } else if (isAgent) {
            return "Internal Transfer";
        } else if (isExternalPhonebook) {
            return formatPhoneNumber(participant.phoneNumber);
        } else {
            return "External Transfer";
        }
    }, [isContact, participant.phoneNumber, participant?.managerAction]);

    const formattedName = useMemo(() => {
        if (isAgent) {
            return agents.find((a) => a.twilioContactUri == participant.phoneNumber)?.name;
        } else if (isContact) {
            return contact.name || participant.name;
        } else if (isExternalPhonebook) {
            return participant.name;
        }
        // if not agent nor contact, then it's an external number
        return formatPhoneNumber(participant.to);
    }, [isAgent, isContact, participant.to, participant.phoneNumber, participant.name, agents, contact?.name]);

    function getParticipantIcon() {
        if (isContact) {
            return "silhouette";
        } else if (isAgent) {
            return "headset";
        } else {
            return "external-transfer";
        }
    }

    useEffect(() => {
        if (isEmpty(participants)) {
            setCallStatus(getDefaultConnectionStatus());
            return;
        }
        const participantsArray = Object.values(participants);
        if (participantsArray.length < 1) {
            setCallStatus("Connecting...");
        } else if (participant.status == "ringing") {
            setCallStatus("Ringing...");
        } else if (participant.status == "in-progress") {
            setCallStatus("Live call");
        } else {
            setCallStatus("Connecting...");
        }
    }, [
        participants
            ? Object.values(participants)
                  .map((p) => p.status)
                  .join(",")
            : [],
    ]);

    useEffect(() => {
        if (!participantConnected) {
            setConferenceDuration({ duration: 0 });
            window.clearInterval(interval.current);
        } else if (participantConnected && !duration) {
            if (!interval.current && participant?.startTimestamp) {
                interval.current = window.setInterval(() => {
                    // bug bash clean up, we should really know if we have the participant or not
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    dispatch(setConferenceDuration({ duration: dayjs().unix() - participant!.startTimestamp! }));
                }, 1000);
            }
        }
    }, [participantConnected, participant?.startTimestamp]);

    useEffect(() => {
        return () => {
            if (interval.current) {
                window.clearInterval(interval.current);
                interval.current = 0;
            }
        };
    }, []);

    return (
        <div data-testid="call-participant" className={clsx(styles.contactInfo, styles.layoutContainer)}>
            <div className={clsx(styles.layoutContainer, styles.contactName)}>
                <Icon
                    icon={getParticipantIcon()}
                    variant={IconVariants.CIRCLE}
                    size={IconSizes.LARGE}
                    bgColor="purple-1"
                    color="purple-4"
                    className={isContact ? "" : styles.externalTransfer}
                />
                <div className={styles.infoContainer}>
                    {participant.name && (
                        <div className={clsx(styles.name, "ellipsis")}>
                            <b>{formattedName}</b>
                        </div>
                    )}
                    <div className={styles.number}>{participantTypePlaceholder}</div>
                </div>
            </div>
            <div className={clsx(styles.status, "flex-column align-end")}>
                <div>
                    {callStatus}
                    {participantConnected && <span>&nbsp;|&nbsp;{formattedTime}</span>}
                </div>
                <div className={clsx(styles.buttons, "flex-row")}>
                    {shouldDisplayIndependentActionButtons && (
                        <>
                            <CallActionButton
                                triggered={participant.onHold}
                                callBack={holdWrap}
                                icon="hold-call"
                                className={styles.actionButton}
                                togglerClassName={styles.toggler}
                                messages={messages.hold}
                                disabled={isHoldDisabled}
                            />
                            <CallActionButton
                                callBack={dropParticipantByCallSid}
                                icon="call-hang-up-cobra"
                                className={styles.hangUp}
                                messages={messages.hangUp}
                                disabled={isHangUpDisabled}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
