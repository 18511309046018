import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

import { Device, Call } from "@twilio/voice-sdk";
import { noop, omit } from "lodash";
import { useSelector } from "react-redux";

import { hasMicPerms$ } from "App/MicPermissionsCheck";
import { useWrapTask } from "Hooks/useWrapTask/useWrapTask";
import { useFlags } from "Services/FeatureFlagService";
import { addLoggerGlobalContext, getLogger, renderErrorMessage } from "Services/LoggingService";
import {
    selectAgentPreferredEdge,
    selectOnActiveCall,
    setOnActiveCall,
} from "Services/state/agent/AgentInformationSlice";
import { selectConversationsToken } from "Services/state/brand";
import {
    clearConference,
    setConferenceConnectionSid,
    setDeviceParams,
} from "Services/state/conferences/ConferenceStateSlice";
import { updateConnectionQualityStatistics } from "Services/state/conferences/ConnectionQualityStateSlice";
import {
    warn as networkQualityWarning,
    resolveWarning as resolveNetworkQualityWarning,
    clearWarnings as clearNetworkQualityWarnings,
} from "Services/state/conferences/NetworkQualitySlice";
import { clearManagerAction } from "Services/state/manager-action/Thunks";
import { useRVDispatch } from "Services/state/Storage";
import { ConnectionStats } from "Types/ConnectionStats";
import { TwilioError, errorCodeMappings } from "Types/TwilioError";

import { logger, deviceLogger, type TwilioLogLevel } from "./deviceLogger";

const errorMessageCache: Record<string, number> = {};
const callStateLogger = getLogger("Twilio Connection Warnings");
const audioStatisticsLogger = getLogger("Audio statistics");

export const DEVICE_DEFAULT_OPTIONS: Device.Options = {
    codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
};

export const __DEFAULT_DEVICE_EDGES = ["roaming", "ashburn"];

type TwilioDeviceState = {
    device?: Device;
    activeCall?: Call;
    setActiveCall: (call?: Call) => void;
};

export const TwilioDeviceContext = createContext<TwilioDeviceState>({
    device: undefined,
    activeCall: undefined,
    setActiveCall: noop,
});

export function TwilioDeviceProvider({ children }: PropsWithChildren): JSX.Element {
    const [activeCall, setActiveCall] = useState<Call | undefined>();
    const [device, setDevice] = useState<Device | undefined>();
    const token = useSelector(selectConversationsToken);
    const preferredEdge = useSelector(selectAgentPreferredEdge);
    const { voiceSdkLogLevel, voiceSdkDdLogger, voiceSdkCloseProtection } = useFlags();
    const logLevel = voiceSdkLogLevel as TwilioLogLevel;

    useEffect(() => {
        if (!token) {
            setDevice(undefined);
            return;
        }

        if (device) {
            if (token !== device.token) {
                device.updateToken(token);
            }
            if (preferredEdge && preferredEdge !== device.edge) {
                device.updateOptions({ edge: [preferredEdge, ...__DEFAULT_DEVICE_EDGES] });
            }
            return;
        }

        const newDevice = new Device(token, {
            ...DEVICE_DEFAULT_OPTIONS,
            logLevel,
            closeProtection: voiceSdkCloseProtection,
            ...(preferredEdge ? { edge: [preferredEdge, ...__DEFAULT_DEVICE_EDGES] } : {}),
        });

        deviceLogger.setDefaultLevel(logLevel);
        if (voiceSdkDdLogger) {
            newDevice["_log"] = deviceLogger;
        }

        setDevice(newDevice);
        newDevice.register().catch((error) => {
            renderErrorMessage({
                content: "An error occurred while registering for phone calls. Please refresh the page and try again.",
                error: error instanceof Error ? error : new Error(String(error)),
                logger,
                extraData: { error },
            });
        });
    }, [token, preferredEdge, device, logLevel, voiceSdkDdLogger, voiceSdkCloseProtection]);

    useEffect(() => {
        if (!device) {
            return;
        }

        device.updateOptions({ logLevel });
        if (voiceSdkDdLogger) {
            device["_log"] = deviceLogger;
        }
    }, [device, logLevel, voiceSdkDdLogger]);

    useEffect(() => {
        if (!device) {
            return;
        }

        device.updateOptions({ closeProtection: voiceSdkCloseProtection });

        // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to watch the flag.
    }, [voiceSdkCloseProtection]);

    return (
        <TwilioDeviceContext.Provider value={{ device, activeCall, setActiveCall }}>
            {children}
        </TwilioDeviceContext.Provider>
    );
}

export const useTwilioDevice = (): TwilioDeviceState => useContext(TwilioDeviceContext);

/**
 * This hook sets up the needed Twilio device listeners. IT IS **NOT REUSABLE**. DO NOT INVOKE MORE THAN ONCE IN THE WHOLE APP.
 * It returns some of its handlers just for testing purposes.
 */
export function useTwilioDeviceListeners(): {
    handleIncomingCall: (call: Call) => void;
    handleCallConnected: (connection: Call) => void;
    handleCallDisconnected: (connection: Call) => void;
} {
    const [connStats, setConnStats] = useState<Array<ConnectionStats>>([]);
    const [totalConnStats, setTotalConnStats] = useState<ConnectionStats["totals"]>(
        {} as unknown as ConnectionStats["totals"]
    );
    const { device, setActiveCall } = useContext(TwilioDeviceContext);
    const wrapTask = useWrapTask();

    const dispatch = useRVDispatch();
    const onActiveCall = useSelector(selectOnActiveCall);

    useEffect(() => {
        if (device && !device.listeners("error").includes(handleTwilioError)) {
            logger.log("register device listeners");
            device.on(Device.EventName.Registered, updateEdgeInformation);
            device.on(Device.EventName.Error, handleTwilioError);
            device.on(Device.EventName.Incoming, handleIncomingCall);
            device.on(Device.EventName.Unregistered, handleDeviceUnregistered);
        }

        return () => {
            if (device && !device.isBusy) {
                logger.log("unregister device listeners");
                device.off(Device.EventName.Registered, updateEdgeInformation);
                device.off(Device.EventName.Error, handleTwilioError);
                device.off(Device.EventName.Incoming, handleIncomingCall);
                device.off(Device.EventName.Unregistered, handleDeviceUnregistered);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to watch the device.
    }, [device]);

    function updateEdgeInformation() {
        if (!device) {
            return;
        }
        const { edge, state } = device;
        logger.log(`Device status: ${state} connected to edge: ${edge}`);
        addLoggerGlobalContext("twilio.edge", String(edge));
    }

    function handleTwilioError(error: TwilioError) {
        let errorMessage = errorCodeMappings[error.code] || "Unknown twilio error encountered";
        errorMessage += `. Error code: ${error.code}`;
        const errorKey = JSON.stringify(error);

        renderErrorMessage({
            content: errorMessage,
            logger,
            error: error.twilioError?.originalError || new Error(errorMessage),
            extraData: { error },
        });

        if ([31205, 31204].includes(error.code)) {
            const deviceToken = device?.token;
            if (deviceToken) {
                logger.error(`Twilio voice device error ${error.code}`, {
                    deviceToken,
                });
            }
        }

        if (errorMessageCache[errorKey] && Date.now() - errorMessageCache[errorKey] < 10 * 1000) {
            return;
        }
        errorMessageCache[errorKey] = Date.now();
    }

    // this is where we capture any call that comes into our device
    function handleIncomingCall(call: Call): void {
        logger.log("Incoming event received");
        if (hasMicPerms$.getValue()) {
            bindCallEvents(call);
            call.accept();
        } else {
            logger.error("Rejecting incoming call due to lack of mic permissions");
            renderErrorMessage({ content: "Microphone has not been found.", logger });
            call.reject();
            clearActiveConferenceState();
            wrapCallTaskIfNeeded(call);
        }
    }

    function handleCallConnected(call: Call): void {
        const callStatus = call.status();
        logger.log("Call accepted with status:", {
            callStatus,
            taskSid: call.customParameters.get("taskSid"),
            originalTaskSid: call.customParameters.get("originalTaskSid"),
            progressiveDialObservabilityMeasure: call.customParameters.get("taskType") === "Progressive Dial",
        });

        if (callStatus == Call.State.Closed) {
            dispatch(setOnActiveCall(false));
            setActiveCall();
            dispatch(clearConference());
            return;
        }

        const connectionCallSid = call.parameters.CallSid;
        if (connectionCallSid) {
            dispatch(setConferenceConnectionSid({ connectionCallSid }));
            dispatch(setOnActiveCall(true));
            setActiveCall(call);

            const taskSid = call.customParameters.get("taskSid");
            dispatch(
                setDeviceParams({
                    taskSid,
                    ...omit(call.parameters, ["AccountSid"]),
                })
            );
        }
    }

    function handleDeviceUnregistered() {
        logger.log("Device unregistered");
    }

    function wrapCallTaskIfNeeded(call: Call) {
        const taskSid = call.customParameters.get("taskSid");
        const managerAction = call.customParameters.get("managerAction");
        if (taskSid && !managerAction) {
            logger.log("Trying to wrap task after twilio device disconnected", { taskSid });
            // Tasks only automatically wrap when their associated conference ends.
            // Wrapping tasks manually ensures that an agent who leaves a conference with 2+ other participants can automatically wrap & disposition their task.
            wrapTask(taskSid);
        }
    }

    function handleCallDisconnected(call: Call) {
        logger.log(`Call disconnected with status:`, { callStatus: call.status() });
        wrapCallTaskIfNeeded(call);
        clearActiveConferenceState(call);
        logger.log("Device disconnect agent on active call status", { onActiveCall });
    }

    function clearActiveConferenceState(call?: Call) {
        dispatch(setOnActiveCall(false));
        setActiveCall();
        if (call?.customParameters.get("managerAction")) {
            dispatch(clearManagerAction());
        } else {
            logger.log("Clearing conference state", {
                call,
            });
            dispatch(clearConference());
        }
        setDeviceParams({ taskSid: undefined });
    }

    function bindCallEvents(call: Call) {
        const connectionDetails = {
            // Twilio stores the call sid in different places depending on the direction of the call
            callSid: call.parameters.CallSid ?? call.outboundConnectionId,
            taskSid: call.customParameters.get("taskSid"),
        };

        call.on("accept", handleCallConnected);
        call.on("accept", () => {
            // For some reason, certain calls drop this field
            connectionDetails.callSid = call.parameters.CallSid;
            callStateLogger.log("Starting call", { call: connectionDetails });
        });

        call.on("warning", (name, data) => {
            callStateLogger.log(`Received warning: '${name}'`, {
                name,
                ...data,
                totalConnStats,
                recentStats: connStats.slice(-5),
            });
            dispatch(networkQualityWarning(name));
        });

        call.on("warning-cleared", (name) => {
            dispatch(resolveNetworkQualityWarning(name));
        });

        call.on("cancel", () => {
            callStateLogger.log("Call cancelled", { call: connectionDetails });
            clearActiveConferenceState(call);
            dispatch(clearNetworkQualityWarnings());
        });

        call.on("error", (error) => {
            callStateLogger.log("Call got error", { call: connectionDetails, error });
        });

        call.on("reconnecting", (error) => {
            callStateLogger.log("Call reconnecting from error", { call: connectionDetails, error });
        });

        call.on("reconnected", () => {
            callStateLogger.log("Call reconnected", { call: connectionDetails });
        });

        call.on("disconnect", handleCallDisconnected);
        call.on("disconnect", () => {
            callStateLogger.log("Call ended", { call: connectionDetails });
            dispatch(clearNetworkQualityWarnings());
        });

        call.on("sample", (sample) => {
            audioStatisticsLogger.debug(sample);
            addConnectionStat(sample);
            const taskSid = call.customParameters.get("taskSid");
            if (taskSid) {
                dispatch(updateConnectionQualityStatistics(taskSid, sample));
            }
        });

        function addConnectionStat({ totals, timestamp, ...stats }: ConnectionStats): void {
            const expiredSampleTime = Math.floor(timestamp) - 60 * 1000; // Expire samples more than 60 seconds old

            setTotalConnStats(totals);

            (stats as ConnectionStats).timestamp = timestamp;
            setConnStats((prevConnStats) => {
                return prevConnStats
                    .filter(({ timestamp }) => timestamp < expiredSampleTime)
                    .concat(stats as ConnectionStats);
            });
        }
    }
    return {
        handleCallConnected,
        handleIncomingCall,
        handleCallDisconnected,
    };
}
