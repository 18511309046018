import { getLogger } from "Services/LoggingService";
import { reduxStore } from "Services/state/Storage";
import { Brand, MessagingConfig } from "Types/Brand";

const logger = getLogger("User notifications");

export function forceGetConfig(loadedConfig: Brand["messagingConfig"] | undefined): MessagingConfig {
    if (loadedConfig?.appNotifications) {
        return loadedConfig.appNotifications;
    }

    const { brand } = reduxStore.getState();

    if (!brand?.brand?.messagingConfig) {
        logger.warn("Could not find messaging config in redux store", { brandConfig: brand });
        return { enabled: false };
    }

    return brand.brand.messagingConfig?.appNotifications;
}
