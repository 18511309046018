import { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

import { useLookerDashboards } from "Hooks/useLookerDashboards";
import { useServerSentEvent } from "Hooks/useServerSentEvent";
import { useTwilioDeviceListeners } from "Hooks/useTwilioDevice/useTwilioDevice";
import { logger as authLogger, useBrandSlug } from "Services/AuthService";
import { useFlags } from "Services/FeatureFlagService";
import { removeClientPrefixFromString } from "Services/HelpersService";
import { getLogger, renderErrorMessage } from "Services/LoggingService";
import { getAuthenticatedUser } from "Services/marketing-api/agents/queries";
import { getCurrentBrand } from "Services/marketing-api/brands/queries";
import { selectAgentEmail, setAgentData } from "Services/state/agent/AgentInformationSlice";
import { selectBrand, updateBrand } from "Services/state/brand";
import { setContactAttributes } from "Services/state/contact-attributes";
import { subscribeToContactUpdates, unsubscribeToContactUpdates } from "Services/state/contacts/SSESubscription";
import { useRVDispatch } from "Services/state/Storage";
import { subscribeToTaskSSE, unSubscribeToTaskSSE } from "Services/state/tasks/SSESubscription";
import { useTabDetection } from "Services/TabDetectionService";
import { ContactAttribute } from "Types/ContactAttribute";

import { getContactAttributes } from "../../contexts/queries";
import { brandMapper } from "../Utils/BrandMapper";

const logger = getLogger("Twilio Manager");

/**
 * PROVIVDER HOOKS
 */
const useProvideContactAtrributes = () => {
    const brandSlug = useBrandSlug();
    const dispatch = useRVDispatch();

    useQuery<{ getContactAttributes: Array<ContactAttribute> }>(getContactAttributes, {
        skip: !brandSlug,
        errorPolicy: "all",
        onCompleted: (data) => {
            dispatch(setContactAttributes(data.getContactAttributes));
        },
        onError(error) {
            renderErrorMessage({
                content: "Couldn't retrieve data",
                error,
                loggerContext: "Query getContactAttributes",
            });
        },
    });
};

const useProvideAgentInfo = () => {
    const agentEmail = useSelector(selectAgentEmail);
    const dispatch = useRVDispatch();
    const { refetch } = useQuery(getAuthenticatedUser, {
        onError(error) {
            logger.warn("Couldn't retrieve agent data", { error });
        },

        onCompleted({ getAuthenticatedUser }) {
            const {
                email,
                status,
                statusChangedAt,
                twilioAttributes,
                twilioContactUri,
                twilioSid,
                name,
                preferences,
                gmailIntegration,
            } = getAuthenticatedUser;
            dispatch(
                setAgentData({
                    phoneNumber: twilioAttributes?.agentRegalVoicePhone,
                    email,
                    name,
                    workerSid: twilioSid,
                    workerUri: twilioContactUri ? removeClientPrefixFromString(twilioContactUri) : undefined,
                    status: status,
                    statusChangedAt: statusChangedAt,
                    attributes: twilioAttributes,
                    preferences,
                    gmailIntegration,
                })
            );
        },
    });

    useServerSentEvent("sse", "connection.open", async () => {
        await refetch();
    });

    useServerSentEvent("sse", "user.updated", (event) => {
        if (event.twilioEmail !== agentEmail) {
            return;
        }

        dispatch(
            setAgentData({
                phoneNumber: event.twilioAttributes?.agentRegalVoicePhone,
                email: event.twilioEmail,
                workerSid: event.twilioWorkerSid,
                workerUri: event.twilioContactUri ? removeClientPrefixFromString(event.twilioContactUri) : undefined,
                status: event.twilioStatus,
                statusChangedAt: String(event.twilioStatusChangedAt),
                attributes: event.twilioAttributes,
            })
        );
    });
};

const useUserProviderSetup = () => {
    useProvideContactAtrributes();
    useProvideAgentInfo();
    useLookerDashboards(); // prefetch looker dashboards
    useTabDetection();
    useTwilioDeviceListeners();
};

/**
 * SSE HOOKS
 */
const useSSESubscriptions = () => {
    const { multipleCalls } = useFlags();
    useEffect(() => {
        subscribeToContactUpdates();
        subscribeToTaskSSE({ multipleCalls });
        return () => {
            unsubscribeToContactUpdates();
            unSubscribeToTaskSSE();
        };
    }, [multipleCalls]);
};

/**
 * BRAND HOOKS
 */
const useProvideBrandInfo = () => {
    const brandSlug = useBrandSlug();
    const brand = useSelector(selectBrand);
    const dispatch = useRVDispatch();

    useQuery(getCurrentBrand, {
        skip: !brandSlug,
        errorPolicy: "all",
        onError: (error) => {
            renderErrorMessage({
                content: error,
                error,
                duration: 10,
            });
        },
        onCompleted: (data) => {
            const mappedBrand = brandMapper(data.getBrand);
            authLogger.log("Fetched and now updating brand in AuthenticatedUserContext because accessToken changed", {
                brand: mappedBrand,
            });
            // getCurrentBrand graphql query returns different data than authenticateBrand()
            dispatch(updateBrand(mappedBrand));
        },
    });
    return { brand };
};

/**
 * HOOKS EXPORT
 */
export const useAppSetupHooks = () => {
    useUserProviderSetup();
    useSSESubscriptions();
    return useProvideBrandInfo();
};
