import { useEffect, useMemo } from "react";

import { cloneDeep, debounce } from "lodash";
import { useSelector } from "react-redux";

import { audioElements, play } from "Components/elements/UserNotifier/helpers/audio.helpers";
import { useVisualNotification } from "Components/elements/UserNotifier/hooks/useVisualNotification";
import { getLogger } from "Services/LoggingService";
import { selectOnActiveCall } from "Services/state/agent/AgentInformationSlice";
import { reduxStore } from "Services/state/Storage";
import { selectReservationSidStatusKey, selectTasks } from "Services/state/tasks/Selectors";
import { TasksState } from "Services/state/tasks/TasksStateSlice";
import { PromiseChain } from "Utils/PromiseChain";

import { getEventConfigs, getTaskAudioConfig } from "./helpers";

const logger = getLogger("useNotifications");

const adjustNotificationsQueue = new PromiseChain<void, typeof adjustNotificationsToNewTaskState>("Notifications");

export function useNotifications(): void {
    const tasksState = useSelector(selectTasks);
    const { showVisualNotification } = useVisualNotification();
    const onActiveCall = useSelector(selectOnActiveCall);
    const reservationSidStatusKey = useSelector(selectReservationSidStatusKey);

    /**
     * Changes to the reservationSidStatusKey can happen in bursts, often with the same task switching back and forth between statuses.
     * Since stopping is sync and playing is async, this can cause race conditions.
     * We should wait for that dust to settle before doing anything.
     */
    const handleChangeInReservationStatuses = useMemo(
        () =>
            debounce(
                () => {
                    adjustNotificationsQueue.addJobToQueue(() =>
                        adjustNotificationsToNewTaskState(tasksState, showVisualNotification)
                    );
                },
                250,
                { trailing: true }
            ),
        [showVisualNotification, tasksState]
    );

    useEffect(() => {
        handleChangeInReservationStatuses();
    }, [handleChangeInReservationStatuses, reservationSidStatusKey]);

    useEffect(() => {
        if (onActiveCall) {
            stopAllAudioNotifications();
        }
    }, [onActiveCall]);
}

let currentPendingTasks: TasksState = {};

export async function adjustNotificationsToNewTaskState(
    tasksState: TasksState,
    showVisualNotificationFn: (text: string, taskSid: string) => void
): Promise<void> {
    const newPendingTasks = getPendingTasks(tasksState);
    const { removedTasks, addedTasks } = diffTaskStates(currentPendingTasks, newPendingTasks);

    // Stop THEN show to allow for this scenario:
    // Task A and B are both incoming calls. A is in removedTasks, B is in addedTasks.
    // We want to stop for A, then start for B. Why?
    // If we do the opposite, starting for B will do nothing since A hasn't stopped yet.
    // Then stopping for A will stop all incoming call notifications, leaving B unnotified.
    if (Object.keys(removedTasks).length) {
        try {
            stopNotifications(removedTasks);
        } catch (error) {
            logger.error("useNotifications - Error stopping notifications", { error, removedTasks });
        }
    }
    if (Object.keys(addedTasks).length) {
        try {
            await startNotifications(addedTasks, showVisualNotificationFn);
        } catch (error) {
            logger.error("useNotifications - Error starting notifications", { error, addedTasks });
        }
    }

    currentPendingTasks = newPendingTasks;
}

export function getPendingTasks(taskState: TasksState) {
    const newPendingTasks: TasksState = {};
    Object.entries(taskState).forEach(([resSid, task]) => {
        const isNotSecondPowerDialTask = !(
            task.taskChannelUniqueName !== "default" &&
            task.taskChannelUniqueName !== "regal" &&
            task.attributes.autoAnswer === true
        );
        // TODO: Is there ever a pending auto dial task?
        if (task.status === "pending" && isNotSecondPowerDialTask) {
            newPendingTasks[resSid] = cloneDeep(task);
        }
    });
    logger.debug("useNotifications - New pending tasks", { newPendingTasks });
    return newPendingTasks;
}

export function diffTaskStates(
    oldTaskState: TasksState,
    newTaskState: TasksState
): { removedTasks: TasksState; addedTasks: TasksState } {
    const combinedTaskStates = { ...oldTaskState, ...newTaskState };
    const diff: { removedTasks: TasksState; addedTasks: TasksState } = { removedTasks: {}, addedTasks: {} };

    for (const resSid in combinedTaskStates) {
        const task = combinedTaskStates[resSid];

        if (!oldTaskState[resSid] && !!newTaskState[resSid]) {
            diff.addedTasks[resSid] = task;
        } else if (!!oldTaskState[resSid] && !newTaskState[resSid]) {
            diff.removedTasks[resSid] = task;
        }
    }

    logger.debug("useNotifications - Diff in task states", {
        combinedTaskStates: Object.keys(combinedTaskStates),
        added: Object.keys(diff.addedTasks),
        removed: Object.keys(diff.removedTasks),
    });
    return diff;
}

export function stopNotifications(taskStateRemovals: TasksState): void {
    logger.debug("useNotifications - stopNotifications called", { taskSids: Object.keys(taskStateRemovals) });
    for (const resSid in taskStateRemovals) {
        const task = taskStateRemovals[resSid];
        const audioElement = audioElements.get(getTaskAudioConfig(task.attributes.title)?.url || "");
        if (!audioElement?.stop) {
            logger.debug(`useNotifications - Skipping stopping for ${resSid} because stop function unfound`, { task });
            continue;
        }
        logger.debug(`useNotifications - Stopping notification for ${resSid}`, { task });
        audioElement.stop();
    }
}

export async function startNotifications(
    taskStateAdditions: TasksState,
    showVisualNotificationFn: (text: string, taskSid: string) => void
): Promise<void> {
    logger.debug("useNotifications - startNotifications called", { taskSids: Object.keys(taskStateAdditions) });

    const onActiveCall = selectOnActiveCall(reduxStore.getState());
    const audioNotificationsDisabled = !getEventConfigs().enabled;

    for (const resSid in taskStateAdditions) {
        const task = taskStateAdditions[resSid];
        const taskTitle = task.attributes?.title;

        // useVisualNotification determines when to *actually* show the notification or not
        // it should only be when the agent is not on the agent desktop page
        showVisualNotificationFn(`You have a new ${taskTitle} task`, task.taskSid);

        if (onActiveCall || audioNotificationsDisabled) {
            logger.debug(
                `useNotifications - Skipping audio notification for ${resSid} because notifications should not be played right now`,
                {
                    task,
                    onActiveCall,
                    audioNotificationsDisabled,
                }
            );
            continue;
        }

        const taskAudioConfig = getTaskAudioConfig(taskTitle);
        if (!taskAudioConfig.url) {
            logger.debug(
                `useNotifications - Skipping audio notification for ${resSid} because no URL is present to play`,
                {
                    task,
                    taskAudioConfig,
                }
            );
            continue;
        }

        try {
            logger.debug(`useNotifications - Attempting to play audio notification for ${resSid}`, { task });
            await play(taskAudioConfig.url, {
                loop: taskAudioConfig.loop,
                taskSid: task.taskSid,
                logContext: {
                    source: "useNotifications",
                    task,
                },
            });
            const audioElement = audioElements.get(taskAudioConfig.url)?.audio;
            if (audioElement && !audioElement.paused && !audioElement.ended) {
                logger.debug(`useNotifications - Playing audio notification for ${resSid}`, { task });
            } else {
                logger.error(`useNotifications - Failed to play notifications for ${resSid}`, { task });
            }
        } catch (error: any) {
            logger.error(`useNotifications - Error playing audio notification for ${resSid}`, { error, task });
        }
    }
}

export function stopAllAudioNotifications(): void {
    audioElements.forEach((audioElement) => !audioElement.shouldPlaythrough && audioElement.stop?.());
}

/**
 * ONLY to be used for testing purposes
 */
export function resetCurrentPendingTasks(): void {
    currentPendingTasks = {};
}

/**
 * ONLY to be used for testing purposes
 */
export function getCurrentPendingTasks(): TasksState {
    return JSON.parse(JSON.stringify(currentPendingTasks));
}
