import { useContext, useState } from "react";

import { useMutation } from "@apollo/client";
import { RVTask } from "@regal-voice/shared-types";
import { message } from "antd";
import { omit } from "lodash";

import { UserContext } from "App/contexts";
import { CancelationInfo } from "Pages/tasks/types/RVTaskInstance";
import { trackCancelAllCampaignsTasks } from "Services/AgentApi/AgentApiService";
import { renderErrorMessage } from "Services/LoggingService";
import { cancelTasks } from "Services/marketing-api/tasks/mutations";
import { useRVDispatch } from "Services/state/Storage";
import { clearReservationBySid } from "Services/state/tasks/Thunks";

export enum CancelType {
    Task = 0,
    CampaignTasks = 1,
}

export function getCancelSource(cancelType: CancelType): string {
    if (cancelType == CancelType.CampaignTasks) {
        return "agent_desktop_cancel_all_tasks";
    }

    return "agent_desktop";
}

function getDefaultCancelReason(cancelType: CancelType): { reason: string; source: string } {
    return {
        reason: "",
        source: getCancelSource(cancelType),
    };
}

export function useTaskReject({ task }: { task: RVTask | undefined }) {
    const { user } = useContext(UserContext);
    const [cancelTaskList] = useMutation<{ cancelTasks: string[] }>(cancelTasks);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useRVDispatch();

    async function handleCancelTask(cancelType: CancelType, cancelationObj?: CancelationInfo) {
        setLoading(true);

        const cancelReasons = getDefaultCancelReason(cancelType);
        const cancelationInfo = {
            ...omit(cancelationObj, ["reason", "source"]),
            source: cancelationObj?.source || cancelReasons.source,
            reason: cancelationObj?.reason || cancelReasons.reason,
        };

        try {
            await cancelTaskList({
                variables: {
                    // bug bash clean up, we need to handle not having a task before we get here
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ids: [task!.taskSid],
                    cancelAttributes: {
                        cancelationInfo,
                        reason: cancelationInfo.reason,
                        source: cancelationInfo.source,
                    },
                },
            });
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dispatch(clearReservationBySid(task!.sid));

            if (cancelType === CancelType.Task) {
                message.success("Task canceled");
            } else if (cancelType === CancelType.CampaignTasks) {
                await Promise.all([
                    // cancel all outbound calls for current contact phone
                    cancelTaskList({
                        variables: {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            phoneNumber: task!.attributes.contactPhone,
                            cancelAttributes: {
                                cancelationInfo,
                                reason: cancelationInfo.reason,
                                source: cancelationInfo.source,
                            },
                        },
                    }),
                    // track custom "cancel all" event
                    trackCancelAllCampaignsTasks({
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        phone: task!.attributes.contactPhone,
                        details: {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            canceledBy: user!.email,
                            cancelationSource: cancelReasons.source,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            taskSid: task!.taskSid,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            campaignFriendlyId: task!.attributes.campaignInfo?.friendly_id,
                        },
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        profileId: task!.attributes.profileId,
                    }),
                ]);
                message.success("Request successfully submitted");
            }
        } catch (e: any) {
            renderErrorMessage({ content: "Unable to cancel Task", error: e });
        } finally {
            setLoading(false);
        }
    }

    return { loading, handleCancelTask };
}
