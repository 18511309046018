import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NullOr } from "@regal-voice/shared-types";

export type FilterName = "trackers" | "dispositions" | "handlingAgent" | "campaignName" | "scorecardsTemplates";
export type FilterSchema = {
    label: string;
    value: string;
};

export type FilterEntryValue = Record<
    FilterName,
    {
        values: FilterSchema[];
    }
>;

export type TranscriptionFiltersState = {
    filters: NullOr<FilterEntryValue>;
    isCollapsed: boolean;
};

export const initialState: TranscriptionFiltersState = {
    filters: null,
    isCollapsed: true,
};

const TranscriptionFiltersStateSlice = createSlice({
    name: "transcriptionsFilters",
    initialState,
    reducers: {
        setFilters(state, action: PayloadAction<FilterEntryValue>) {
            state.filters = { ...state.filters, ...action.payload };
        },
        setFiltersCollapsed(state, action: PayloadAction<boolean>) {
            state.isCollapsed = action.payload;
        },
        clearFilters() {
            return { ...initialState };
        },
    },
});

export const { setFilters, setFiltersCollapsed, clearFilters } = TranscriptionFiltersStateSlice.actions;

export default TranscriptionFiltersStateSlice.reducer;
