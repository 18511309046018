import { useState, useEffect, useContext, useMemo, useCallback } from "react";

import { message } from "antd";
import { useSelector } from "react-redux";

import { UserContext } from "App/contexts";
import { useAgentPhoneNumberDetails } from "Hooks/useAgentPhoneNumberDetails";
import { useTwilioDevice } from "Hooks/useTwilioDevice/useTwilioDevice";
import { useRemoveTask } from "Pages/agentDesktop/agentInterface/ActivityView/Header/TaskSummary/TaskSummaryHooks";
import { CK_VOICE_CHANNEL, LEGACY_VOICE_CHANNEL, PRIORITY_CHANNEL } from "Pages/agentDesktop/agentInterface/constants";
import { voicemailDrop } from "Services/ConversationsApiService";
import { renderErrorMessage } from "Services/LoggingService";
import { selectAgentInformation } from "Services/state/agent/AgentInformationSlice";
import { selectConference } from "Services/state/conferences";
import { selectTaskSummaryIsSubmitted } from "Services/state/tasks/Selectors";
import { getAttributes, isPendingInboundConferenceTask, summarizeTask } from "Services/Task.service";

import { BUTTONS_MESSAGES } from "../CallBar.consts";

import type { RVTask } from "@regal-voice/shared-types";
import type { AttributesCampaign } from "Types/Campaign";

const MESSAGES = BUTTONS_MESSAGES.voicemailDrop;

export type UseVoicemailDropOptions = {
    activeConferenceTask?: RVTask;
    disabled?: boolean;
};

export type UseVoicemailDropResult = {
    dropVoicemail: () => void;
    shouldRenderVoicemailDrop: boolean;
    voicemailDropDisabled: boolean;
};

export const useVoicemailDrop = ({ activeConferenceTask, disabled = false }: UseVoicemailDropOptions) => {
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [campaign, setCampaign] = useState<AttributesCampaign>();
    const { brand } = useContext(UserContext);
    const { device } = useTwilioDevice();
    const removeCompletedTask = useRemoveTask();
    const agentInformation = useSelector(selectAgentInformation);
    const { participants, deviceCallSid } = useSelector(selectConference);
    const { regalVoicePhone } = getAttributes(activeConferenceTask);
    const regalVoicePhoneSource = useAgentPhoneNumberDetails(regalVoicePhone);
    const regalVoicePhoneFriendlyName = regalVoicePhoneSource?.internalDisplayName || "";
    const taskSummarySubmitted = useSelector(selectTaskSummaryIsSubmitted)(activeConferenceTask?.sid || "");
    const taskChannel = activeConferenceTask?.channelType;
    const voicemailInstructions = getAttributes(activeConferenceTask as RVTask)?.campaignInfo?.voicemailInstructions;

    const shouldRender = useMemo(
        () => taskChannel && [LEGACY_VOICE_CHANNEL, CK_VOICE_CHANNEL, PRIORITY_CHANNEL].includes(taskChannel),
        [taskChannel]
    );

    const attemptVoiceMailDrop = useCallback(
        async (customerCallSid: string) => {
            try {
                const { success } = await voicemailDrop({
                    customerCallSid,
                    campaignId: campaign?.id,
                });
                // success will be false if the call is not in
                // a state where a voicemail can be dropped
                if (success) {
                    message.success(MESSAGES.success());
                } else {
                    renderErrorMessage({
                        content: MESSAGES.waiting(),
                    });
                }

                return success;
            } catch (err) {
                const error = err instanceof Error ? err : new Error(String(err));
                renderErrorMessage({ content: MESSAGES.error(error.message), error });
            }
            return false;
        },
        [campaign]
    );

    const attemptTaskSummary = useCallback(async () => {
        const sid = activeConferenceTask?.sid;
        try {
            if (!brand || !activeConferenceTask) {
                throw new Error("Brand or task not found.");
            }

            const success = await summarizeTask(
                {
                    brand,
                    task: activeConferenceTask,
                    disposition: "Pre-recorded Voicemail",
                    dispositionedBy: "system",
                    regalVoicePhoneFriendlyName,
                    agent: agentInformation,
                },
                taskSummarySubmitted
            );
            if (success) {
                if (sid) {
                    removeCompletedTask(sid);
                }
                device?.disconnectAll();
                message.success("Task successfully summarized.");
            }
        } catch (err) {
            const error = err instanceof Error ? err : new Error(String(err));
            renderErrorMessage({ content: MESSAGES.error(error.message), error });
        }
    }, [
        brand,
        activeConferenceTask,
        regalVoicePhoneFriendlyName,
        agentInformation,
        taskSummarySubmitted,
        removeCompletedTask,
        device,
    ]);

    const dropVoicemail = useCallback(async () => {
        const customerCallSid = Object.values(participants).find((p) => p.callSid != deviceCallSid)?.callSid;
        if (!customerCallSid) {
            return;
        }

        const dropped = await attemptVoiceMailDrop(customerCallSid);
        if (dropped) {
            await attemptTaskSummary();
        }
    }, [participants, deviceCallSid, attemptVoiceMailDrop, attemptTaskSummary]);

    useEffect(() => {
        if (activeConferenceTask) {
            const campaignInfo = getAttributes(activeConferenceTask).campaignInfo as AttributesCampaign;
            setCampaign(campaignInfo as AttributesCampaign);
            const noVoicemail =
                campaignInfo &&
                !!campaignInfo?.voicemailInstructions &&
                ["empty", "personalized"].includes(campaignInfo?.voicemailInstructions);
            const notInProgress = ["completed", "canceled"].includes(activeConferenceTask.status);
            const inBound = activeConferenceTask?.attributes.direction == "inbound";
            setIsDisabled(
                noVoicemail ||
                    notInProgress ||
                    isPendingInboundConferenceTask(activeConferenceTask) ||
                    disabled ||
                    inBound
            );
        } else {
            setIsDisabled(disabled);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps -- This effect should only run when the task changes
    }, [activeConferenceTask?.status, voicemailInstructions, disabled]);

    return { dropVoicemail, shouldRenderVoicemailDrop: shouldRender, voicemailDropDisabled: isDisabled };
};
