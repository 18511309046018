import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NullOr, RecordingMomentRoles } from "@regal-voice/shared-types";

import { EnhancedRecordingMoment, RecordingDetails, TranscriptionItemParams, TranscriptTabs } from "Types/Transcripts";

export type TranscriptionState = {
    error: NullOr<string>;
    loading: boolean;
    params: NullOr<TranscriptionItemParams>;
    activeTab: TranscriptTabs;
    groupedMoments: NullOr<Record<string, EnhancedRecordingMoment[]>>;
    participants: Record<string, RecordingMomentRoles>;
    recording: NullOr<RecordingDetails>;
};

export const initialState: TranscriptionState = {
    loading: true,
    error: null,
    params: null,
    groupedMoments: null,
    participants: {},
    recording: null,
    activeTab: TranscriptTabs.CALL_DETAILS,
};

const TranscriptionStateSlice = createSlice({
    name: "transcriptions",
    initialState,
    reducers: {
        setRecording(state, action: PayloadAction<RecordingDetails>) {
            state.recording = action.payload;
            state.loading = false;
        },
        setGroupedMoments(state, action: PayloadAction<Record<string, EnhancedRecordingMoment[]>>) {
            state.groupedMoments = action.payload;
        },
        setParticipants(state, action: PayloadAction<Record<string, RecordingMomentRoles>>) {
            state.participants = action.payload;
        },
        setParams(state, action: PayloadAction<TranscriptionItemParams>) {
            state.params = action.payload;
        },
        setActiveTab(state, action: PayloadAction<TranscriptTabs>) {
            state.activeTab = action.payload;
        },
        setError(state, action: PayloadAction<NullOr<string>>) {
            state.error = action.payload;
            state.loading = false;
        },
        clearTranscription() {
            return { ...initialState };
        },
    },
});

export const {
    setRecording,
    setGroupedMoments,
    setParticipants,
    setParams,
    setError,
    clearTranscription,
    setActiveTab,
} = TranscriptionStateSlice.actions;
export default TranscriptionStateSlice.reducer;
