export interface TwilioError {
    code: number;
    message: string;
    twilioError?: {
        code: number;
        causes: string[];
        description: string;
        explanation: string;
        solutions: string[];
        message: string;
        stack: string;
        originalError?: Error;
    };
}

export const errorCodeMappings: Record<number, string> = {
    31000: "General Error encountered",
    31002: "Connection declined",
    31003: "Poor or intermittent Internet connection",
    31005: "No internet",
    31105: "Invalid contact name",
    31201: "Microphone has not been found",
    31204: "Invalid authentication token. Please logout and login again",
    31205: "Authentication expired. Please logout and login again",
    31207: "Authentication expired. Please logout and login again",
    31208: "Microphone has not been enabled",
};
