import { ChangeEventHandler, useCallback, useRef } from "react";

import { AutoComplete, Form, Input, SelectProps } from "antd";

import { useExternalTransferPhoneNumbers } from "Pages/settings/ExternalTransferPhonebook/Hooks/useExternalTransferPhoneNumbers";
import { formatPhoneNumber } from "Services/CommunicationService";
import { useFlags } from "Services/FeatureFlagService";
import { ExternalPhoneNumber } from "Types/ExternalPhoneNumber";

import styles from "./ExternalTransfer.module.scss";

export function OldExternalTransfer(): JSX.Element {
    const form = Form.useFormInstance();
    const EXTERNAL_PHONE_NUMBER_RULES = [
        { required: true, message: "The number field is required" },
        {
            min: 10,
            message: "The number format is incorrect",
        },
    ];

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        ({ target }) => {
            form.setFieldsValue({
                externalPhoneNumber: formatPhoneNumber(target.value),
            });
        },
        [form]
    );

    return (
        <Form.Item name="externalPhoneNumber" rules={EXTERNAL_PHONE_NUMBER_RULES}>
            <Input autoFocus placeholder="External Phone Number" onChange={handleChange} size="large" />
        </Form.Item>
    );
}

export function NewExternalTransfer(): JSX.Element {
    const form = Form.useFormInstance();
    const EXTERNAL_PHONE_NUMBER_RULES = [{ required: true, message: "The number field is required" }];

    const searchableFromNumberWrapper = useRef<HTMLDivElement>(null);
    const { phoneNumbersData } = useExternalTransferPhoneNumbers({ isManagerContext: false });

    const handleOnSelect = useCallback(
        (value: string) => {
            form.setFieldsValue({
                externalPhoneNumber: formatPhoneNumber(+value.split("-")[0]),
                phoneSelect: value.split("-")[1],
            });
        },
        [form]
    );

    const handleOnChange = useCallback(
        (value: string) => {
            form.setFieldsValue({
                externalPhoneNumber: formatPhoneNumber(value),
                phoneSelect: formatPhoneNumber(value),
            });
        },
        [form]
    );

    const filterOption: SelectProps<string>["filterOption"] = (input, option) => {
        if (!option) {
            return false;
        }

        return (option.value as string)?.toLocaleLowerCase().includes(input.replace("+1 ", "").toLocaleLowerCase());
    };

    const options = phoneNumbersData.map((item: Partial<ExternalPhoneNumber>, index: number) => ({
        key: index,
        value: `${item.phoneNumber}-${item.name}`,
        label: (
            <div>
                <div className={styles.name}>{item.name}</div>
                <div className={styles.number}>
                    <span>{formatPhoneNumber(item.phoneNumber)}</span>
                </div>
            </div>
        ),
    }));

    return (
        <div ref={searchableFromNumberWrapper}>
            <Form.Item name="phoneSelect" rules={EXTERNAL_PHONE_NUMBER_RULES}>
                <AutoComplete
                    data-testid="external-transfer-search-input"
                    popupClassName="certain-category-search-dropdown"
                    options={options}
                    getPopupContainer={() => searchableFromNumberWrapper.current as HTMLElement}
                    filterOption={filterOption}
                    onSelect={handleOnSelect}
                    onChange={handleOnChange}
                >
                    <Input size="large" placeholder="External Phone Number" data-testid="enter-phone-number" />
                </AutoComplete>
            </Form.Item>
            <Form.Item name="externalPhoneNumber" hidden>
                <Input hidden data-testid="external-phone-number-value" />
            </Form.Item>
        </div>
    );
}

export function ExternalTransfer(): JSX.Element {
    const { hasExternalTransferPhonebook } = useFlags();
    return hasExternalTransferPhonebook ? <NewExternalTransfer /> : <OldExternalTransfer />;
}
