/**
 * These are selectors that are reliant on the tasks slice of state.
 * They needed to move to their own file to get around a circular dependency issue
 * between the tasks and contacts slices. The selectActiveTask depends on the contacts slice
 */

import { RootState } from "Services/state/Storage";
import { selectTasks } from "Services/state/tasks/Selectors";

import { createContactFromTask, isContactId } from "../utils";
import { selectContactByIdOrPhone } from "./Selectors";

/**
 * returns contact by phone (as a fallback while we migrate to ID driven contacts) or id from contacts slice.
 * If it's not able to find a contact (hasn't been fetched yet), creates a contact from task attributes.
 */
export const selectTaskContact = (state: RootState) => (contactIdOrPhone: string) => {
    const contact = selectContactByIdOrPhone(state, contactIdOrPhone);
    if (contact) {
        return contact;
    }
    const allTasks = selectTasks(state);
    const isId = isContactId(contactIdOrPhone);
    const task = Object.values(allTasks || {}).find(
        (task) =>
            ("profileId" in task.attributes && task.attributes.profileId === contactIdOrPhone) ||
            task.attributes.contactPhone == contactIdOrPhone
    );
    let contactPhone, id;
    if (isId) {
        id = contactIdOrPhone;
    } else {
        contactPhone = contactIdOrPhone;
    }
    return createContactFromTask({ id, contactPhone, task });
};
