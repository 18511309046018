import { useEffect } from "react";

import { useFlags } from "Services/FeatureFlagService";

import type { RVTask, TaskAttributesType } from "@regal-voice/shared-types";

type AllowedTaskTypes = Lowercase<"all" | TaskAttributesType>;

let openTasksUrlTypes: AllowedTaskTypes[] = [];
let openAllTasksUrls = false;

const setTasksUrlTypes = (urlTypes: string) => {
    openTasksUrlTypes = urlTypes.split(",").reduce<AllowedTaskTypes[]>((acc, item) => {
        const safe = item.trim();
        if (safe) {
            acc.push(safe.toLowerCase() as AllowedTaskTypes);
        }

        return acc;
    }, []);
    openAllTasksUrls = openTasksUrlTypes.includes("all");
};

const shouldOpenTasksUrl = (taskType?: string): boolean => {
    return (
        openAllTasksUrls ||
        (typeof taskType === "string" && openTasksUrlTypes.includes(taskType.toLowerCase() as AllowedTaskTypes))
    );
};

export const openTaskUrlIfNeeded = (task: RVTask) => {
    if (!shouldOpenTasksUrl(task.attributes.taskType)) {
        return;
    }

    let { openUrl } = task.attributes;
    if (typeof openUrl !== "string") {
        return;
    }

    if (openUrl.match(/^www\./i)) {
        openUrl = `https://${openUrl}`;
    } else if (!openUrl.match(/^https?:\/\//i)) {
        return;
    }

    window.open(openUrl, "_blank", "noopener");
    window.focus();
};

export const useTasksOpenUrlSettingsLoader = () => {
    const { openTaskUrlWhenAccepted } = useFlags();
    useEffect(() => {
        setTasksUrlTypes(openTaskUrlWhenAccepted);
    }, [openTaskUrlWhenAccepted]);
};
